import React, { Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';

//SVG
import Verify from '../../assets/svgs/approved.svg';

// SCSS
import styles from './AccountVerify.module.scss';

const AccountVerify = () => {
  return (
    <Fragment>
      <div className={styles.verifyPage}>
        <Container>
          <Row>
            <Col>
              <div className={styles.verifyWrapper}>
                <div className={styles.imgWrapper}>
                  <SVG baseURL="/" description="Verify" src={Verify} />
                </div>
                <div className={styles.heading}>
                  Your account has been verified.
                </div>
                <div className={styles.subHeading}>
                  Thank you for confirming. Your account is now verified.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default AccountVerify;
