import React from 'react';
import Search from './Search';

// Constants
import { JOB_TYPE } from 'constants/Constants';

const Opportunities: React.FC = () => {
  return (
    <Search
      title="Services"
      type={JOB_TYPE.opportunity}
      subtitle="Find help for consulting, advisors, services professionals, and more."
    />
  );
};

export default React.memo(Opportunities);
