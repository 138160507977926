import React from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

// Components
import Button from 'components/Button/Button';
import Input from 'components/Form/Input/Input';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import TextArea from 'components/Form/TextArea/TextArea';
import FileUpload from 'components/FileUpload/FileUpload';
import Select from 'components/Form/Select/Select';

import { Multiselect } from 'multiselect-react-dropdown';

// Interfaces
import { UserDetailsType } from 'interfaces/Register.types';

// Constants
import {
  FILE_TYPE,
  ROUTES,
  DISPLAY_PRONOUNS,
  PHONE_TYPES,
  FREE_WORK_CREDENTIALS,
  AFFILIATIONS,
} from 'constants/Constants';
import STATES from 'constants/States';

// SCSS
import styles from './UserDetailsForm.module.scss';
import { Category } from 'interfaces/Category.types';

interface Props {
  categories?: Array<Category>;
  userDetails: UserDetailsType;
  userDetailsErrors: any;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handleCategorySelect: (list: Array<any>, key: string) => void;
  handleWebsiteInputChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleAdditionalLinksChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handlePhotoChange: (photo: File | null) => void;
  handleResumeChange: (resume: File | null) => void;
  isSubmitDisabled: boolean;
  handleSubmit: () => void;
  handleIdeologyChange: (event: any) => void;
  handleSchoolChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  handleCampaignChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
  loading: boolean;
  apiError?: string;
}

const renderTooltipCost = (props: any) => (
  <Tooltip
    className={styles.tooltipContainer}
    id="button-tooltipCost"
    {...props}
  >
    Categories are how we sort Jobs and Services, and also how users display
    their skills on their profiles. Select the Categories you want to showcase
    for hiring/getting hired.
  </Tooltip>
);

const HTTP_PLACEHOLDER = 'http://';

const UserDetailsForm: React.FunctionComponent<Props> = ({
  categories,
  userDetails,
  userDetailsErrors,
  handleInputChange,
  handleCategorySelect,
  handleWebsiteInputChange,
  handleAdditionalLinksChange,
  handlePhotoChange,
  handleResumeChange,
  handleCampaignChange,
  handleSchoolChange,
  handleIdeologyChange,
  isSubmitDisabled,
  handleSubmit,
  loading,
  apiError,
}: Props) => {
  return (
    <Container className={`mb-5 ${styles.registerContinued}`} fluid>
      <Container className="p-0">
        <Row className="my-5">
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerTitle}>Sign Up</div>
            <div className={styles.registerSubTitle}>
              Last step before you complete your profile.
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerIntro}>
              You are almost done. Just need some final information so we can
              create a proper profile. Only completed fields will show.
            </div>
            <div className={`${styles.requiredFieldsNote} text-right`}>
              <span>* </span>= Required Fields
            </div>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Personal Information</div>
              <div className={styles.registerSubLabel}>
                Tell us about yourself.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    First Name
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="firstName"
                    type="text"
                    value={userDetails.firstName}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.firstName ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.firstName && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.firstName}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Last Name
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="lastName"
                    type="text"
                    value={userDetails.lastName}
                    onChange={handleInputChange}
                    className={
                      userDetailsErrors.lastName ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.lastName && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.lastName}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Phone 1 Type</div>
                  <Select
                    id="phone1Type"
                    selectItems={PHONE_TYPES}
                    onChange={handleInputChange}
                    name="phone1Type"
                    value={userDetails.phone1Type}
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Phone Number</div>
                  <Input
                    name="phone1"
                    type="text"
                    value={userDetails.phone1}
                    onChange={handleInputChange}
                    placeholder="(___) ___ - ____"
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Phone 2 Type</div>
                  <Select
                    id="phone2Type"
                    selectItems={PHONE_TYPES}
                    onChange={handleInputChange}
                    name="phone2Type"
                    value={userDetails.phone2Type}
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Phone Number</div>
                  <Input
                    name="phone2"
                    type="text"
                    value={userDetails.phone2}
                    onChange={handleInputChange}
                    placeholder="(___) ___ - ____"
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Display Pronouns
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Select
                    id="displayPronouns"
                    selectItems={DISPLAY_PRONOUNS}
                    onChange={handleInputChange}
                    name="displayPronouns"
                    value={userDetails.displayPronouns}
                  />
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Preferred Pronouns</div>
                  <Input
                    name="preferredPronouns"
                    type="text"
                    value={userDetails.preferredPronouns}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    About{' '}
                    <span style={{ fontWeight: 'normal' }}>
                      (Biography, Accomplishments, etc)
                    </span>
                  </div>
                  <TextArea
                    name="bio"
                    value={userDetails.bio}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Jobs:{' '}
                    <span style={{ fontWeight: 'normal' }}>
                      What type of jobs/services are you searching for?
                    </span>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipCost}
                    >
                      <div className={styles.infoHover}>i</div>
                    </OverlayTrigger>
                    <span className={styles.shortMess}>
                      Select all that apply, and
                      <a
                        href="https://capitolcommunity.activehosted.com/f/5"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        click here{' '}
                      </a>
                      if your type is not listed.
                    </span>
                  </div>
                  <Multiselect
                    options={categories || []}
                    isObject={true}
                    displayValue="name"
                    showCheckbox
                    onSelect={(categories) =>
                      handleCategorySelect(categories, 'careerCategories')
                    }
                    onRemove={(categories) =>
                      handleCategorySelect(categories, 'careerCategories')
                    }
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Services:{' '}
                    <span style={{ fontWeight: 'normal' }}>
                      What should people hire/contact you for?
                    </span>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipCost}
                    >
                      <div className={styles.infoHover}>i</div>
                    </OverlayTrigger>
                    <span className={styles.shortMess}>
                      Select all that apply, and
                      <a
                        href="https://capitolcommunity.activehosted.com/f/5"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {' '}
                        click here{' '}
                      </a>
                      if your type is not listed.
                    </span>
                  </div>
                  <Multiselect
                    className={styles.multiSelectBox}
                    options={categories || []}
                    isObject={true}
                    displayValue="name"
                    showCheckbox
                    onSelect={(categories) =>
                      handleCategorySelect(categories, 'opportunityCategories')
                    }
                    onRemove={(categories) =>
                      handleCategorySelect(categories, 'opportunityCategories')
                    }
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} className="fileUploadWrap">
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Upload Your Photo</div>
                  <FileUpload
                    className="defaultImage"
                    fileType={FILE_TYPE.IMAGE}
                    fileSpecs={[
                      '5mb max',
                      '500px wide',
                      '500px tall',
                      'Capitol Community logo is used if no image',
                    ]}
                    onFileChange={handlePhotoChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Political</div>
              <div className={styles.registerSubLabel}>
                To help connect you with jobs, services and like-minded
                professionals.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Party Affiliation
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Select
                    id="partyAffiliation"
                    selectItems={AFFILIATIONS}
                    onChange={handleInputChange}
                    name="partyAffiliation"
                    value={userDetails.partyAffiliation}
                  />
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Ideology:{' '}
                    <span style={{ fontWeight: 'normal' }}>
                      Who you work with (choose all that apply)
                    </span>
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Checkbox
                    id="conservative"
                    name="ideology"
                    isChecked={
                      userDetails.ideology.indexOf('Conservative') > -1
                    }
                    onChange={handleIdeologyChange}
                    label="Conservative"
                    value="Conservative"
                  />
                  <Checkbox
                    id="moderate"
                    name="ideology"
                    isChecked={userDetails.ideology.indexOf('Moderate') > -1}
                    onChange={handleIdeologyChange}
                    label="Moderate"
                    value="Moderate"
                  />
                  <Checkbox
                    id="progressive"
                    name="ideology"
                    isChecked={userDetails.ideology.indexOf('Progressive') > -1}
                    onChange={handleIdeologyChange}
                    label="Progressive"
                    value="Progressive"
                  />
                  <Checkbox
                    id="prefer_not_to_answer"
                    name="ideology"
                    isChecked={
                      userDetails.ideology.indexOf('Prefer not to answer') > -1
                    }
                    onChange={handleIdeologyChange}
                    label="Prefer not to answer"
                    value="Prefer not to answer"
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Experience</div>
              <div className={styles.registerSubLabel}>
                To help connect you with the best jobs and services available.
                Include any information outside of these fields in your About
                section.
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            {userDetails.campaigns.map((item, i) => {
              return (
                <Row key={i}>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.subHeading}>
                      Campaign/Company #{i + 1}
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Name</div>
                      <Input
                        name="name"
                        type="text"
                        value={userDetails.campaigns[i].name}
                        onChange={(e) => handleCampaignChange(e, i)}
                      />
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Title</div>
                      <Input
                        name="title"
                        type="text"
                        value={userDetails.campaigns[i].title}
                        onChange={(e) => handleCampaignChange(e, i)}
                      />
                    </div>
                  </Col>
                  <Col xl={8} lg={8} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Date Range</div>
                      <Input
                        name="date_range"
                        type="text"
                        value={userDetails.campaigns[i].date_range}
                        onChange={(e) => handleCampaignChange(e, i)}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Education</div>
              <div className={styles.registerSubLabel}>
                To help connect you with the best jobs and services available.
                Include any information outside of these fields in your About
                section.
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            {userDetails.schools?.map((item, i) => {
              return (
                <Row key={i}>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.subHeading}>School #{i + 1}</div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>
                        Level{' '}
                        <span style={{ fontWeight: 'normal' }}>
                          (Degree, etc)
                        </span>
                      </div>
                      <Input
                        name="level"
                        type="text"
                        value={userDetails.schools[i].level}
                        onChange={(e) => handleSchoolChange(e, i)}
                      />
                    </div>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Institution Name</div>
                      <Input
                        name="name"
                        type="text"
                        value={userDetails.schools[i].name}
                        onChange={(e) => handleSchoolChange(e, i)}
                      />
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>
                        Notes{' '}
                        <span style={{ fontWeight: 'normal' }}>
                          (Field of Study, Honors, etc)
                        </span>
                      </div>
                      <Input
                        name="notes"
                        type="text"
                        value={userDetails.schools[i].notes}
                        onChange={(e) => handleSchoolChange(e, i)}
                      />
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Location</div>
              <div className={styles.registerSubLabel}>
                To help find better matches.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    City
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="city"
                    type="text"
                    value={userDetails.city}
                    onChange={handleInputChange}
                    className={userDetailsErrors.city ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.city && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.city}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    State
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Select
                    id="state"
                    selectItems={STATES}
                    onChange={handleInputChange}
                    name="state"
                    value={userDetails.state}
                  />
                  {userDetailsErrors.state && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.state}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Zip
                    <span className={styles.requiredField}> *</span>
                  </div>
                  <Input
                    name="zip"
                    type="text"
                    value={userDetails.zip || ''}
                    onChange={handleInputChange}
                    className={userDetailsErrors.zip ? styles.errorInput : ''}
                  />
                  {userDetailsErrors.zip && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.zip}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Add Additional Links</div>
              <div className={styles.registerSubLabel}>
                Personal or Business.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>
                    Personal or Business Website
                  </div>
                  <Input
                    name="website"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.website}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.website ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.website && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.website}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Facebook</div>
                  <Input
                    name="facebook"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.facebook}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.facebook ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.facebook && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.facebook}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Twitter</div>
                  <Input
                    name="twitter"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.twitter}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.twitter ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.twitter && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.twitter}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>LinkedIn</div>
                  <Input
                    name="linkedin"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.linkedin}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.linkedin ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.linkedin && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.linkedin}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={6} lg={6} md={6} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Instagram</div>
                  <Input
                    name="instagram"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.instagram}
                    onChange={handleWebsiteInputChange}
                    className={
                      userDetailsErrors.instagram ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.instagram && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.instagram}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div>Additional Links</div>
              </Col>
              <Col xl={4} lg={4} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Title</div>
                  <Input
                    name="additionalLink1Title"
                    type="text"
                    placeholder="Title"
                    value={userDetails.additionalLink1.title}
                    onChange={handleAdditionalLinksChange}
                    className={
                      userDetailsErrors.additionalLink1Title
                        ? styles.errorInput
                        : ''
                    }
                  />
                </div>
              </Col>
              <Col xl={8} lg={8} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>URL</div>
                  <Input
                    name="additionalLink1"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.additionalLink1.link}
                    onChange={handleAdditionalLinksChange}
                    className={
                      userDetailsErrors.additionalLink1 ? styles.errorInput : ''
                    }
                  />
                  {userDetailsErrors.additionalLink1 && (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {userDetailsErrors.additionalLink1}
                      </div>
                    </Alert>
                  )}
                </div>
              </Col>

              <Col xl={4} lg={4} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Title</div>
                  <Input
                    name="additionalLink2Title"
                    type="text"
                    placeholder="Title"
                    value={userDetails.additionalLink2.title}
                    onChange={handleAdditionalLinksChange}
                    className={
                      userDetailsErrors.additionalLink2Title
                        ? styles.errorInput
                        : ''
                    }
                  />
                </div>
              </Col>
              <Col xl={8} lg={8} md={12} sm={12}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>URL</div>
                  <Input
                    name="additionalLink2"
                    type="text"
                    placeholder={HTTP_PLACEHOLDER}
                    value={userDetails.additionalLink2.link}
                    onChange={handleAdditionalLinksChange}
                    className={
                      userDetailsErrors.additionalLink2 ? styles.errorInput : ''
                    }
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.registerLabelSection}>
              <div className={styles.registerLabel}>Resume & Credentials</div>
              <div className={styles.registerSubLabel}>
                Resume & Credentials Be careful about sharing information like
                persona emails of phone numbers. Onsite messaging is available
                to all users.
              </div>
              <div className={styles.subDescription}>
                Credentials help you verify skills/work experience through a
                third-party partner. All Credentials are free to earn, displayed
                on your profile, and can help you unlock career opportunities,
                educational content, and more. Credentialing is quick and easy;
                click the &quot;Free Work/Skill Credentials&quot; button to get
                started.
              </div>
            </div>
          </Col>

          <Col xl={6} lg={6} md={12} sm={12}>
            <Row className={styles.authButton}>
              <Col xl={12} lg={12} md={6} sm={12}>
                <Row>
                  <Col className="p-0 fileUploadWrap">
                    <div className={styles.authInput}>
                      <div className={styles.authLabel}>Upload Your Resume</div>
                      <FileUpload
                        fileType={FILE_TYPE.DOCUMENT}
                        fileSpecs={['pdf and doc files only', '5mbs max']}
                        onFileChange={handleResumeChange}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <a
                  href={FREE_WORK_CREDENTIALS}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="btn-grey-ghost"
                >
                  Free Work/Skill Credentials: Click Here
                </a>
              </Col>
              <Col
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className={styles.signUpTerms}
              >
                By signing up you are agreeing to our{' '}
                <a
                  href={ROUTES.TERMS}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service.
                </a>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12}>
                <Button
                  type="large  w-100"
                  label={loading ? 'loading...' : 'Finish My Account'}
                  disabled={loading || isSubmitDisabled}
                  onClick={handleSubmit}
                ></Button>
                {apiError && (
                  <Alert variant="danger">
                    <div className={styles.error}>{apiError}</div>
                  </Alert>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(UserDetailsForm);
