import React, { useState, useContext } from 'react';

// Components
import Button from '../../../../../components/Button/Button';
import History from 'utils/History';

// Context
import GlobalContext from 'contexts/Global.context';

// SCSS
import styles from './Content.module.scss';

// Services
import { reportUserService } from 'services/UserService';

interface Props {
  jobDescription?: string;
  aboutTheLabel?: string;
  postedBy?: string;
}

const Content: React.FC<Props> = ({
  jobDescription,
  aboutTheLabel,
  postedBy,
}: Props) => {
  const [reported, setReported] = useState<boolean>(false);

  const { isLoggedIn } = useContext(GlobalContext);

  const reportUser = async () => {
    if (isLoggedIn) {
      window.analytics.track('Report User', {
        postedBy,
      });
      setReported(true);
      await reportUserService(postedBy);
    } else {
      History.push('/login?destination=' + window.location.pathname);
    }
  };

  return (
    <div className={styles.resultContent}>
      <div className={styles.resultAbout}>
        <Button
          disabled={reported}
          label={!reported ? 'Report This User' : 'User Has Been Reported'}
          type="outline-gray noRadius"
          onClick={reportUser}
        />
        <div className={styles.resultAboutLabel}>Job Description</div>
        <pre>{jobDescription}</pre>
      </div>

      <div className={styles.resultAbout}>
        <div className={styles.resultAboutLabel}>
          Hiring Company Description
        </div>
        <pre>{aboutTheLabel}</pre>
      </div>
    </div>
  );
};

export default React.memo(Content);
