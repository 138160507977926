import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

// Components
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Form/Input/Input';
import TextArea from '../../../../components/Form/TextArea/TextArea';
import FileUpload from 'components/FileUpload/FileUpload';
import Select from 'components/Form/Select/Select';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import Loader from 'components/Loader/Loader';

import { Multiselect } from 'multiselect-react-dropdown';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import { ROUTES } from 'constants/Constants';
import STATES from 'constants/States';

// Styles
import styles from './EditProfile.module.scss';

// Types
import { Category } from 'interfaces/Category.types';

import {
  fetchUserDetailsService,
  updateUserAccountInfoService,
} from 'services/UserService';
import {
  fetchCategoriesService,
  getUploadURLService,
} from 'services/CommonService';

import {
  FILE_TYPE,
  PHONE_TYPES,
  DISPLAY_PRONOUNS,
  AFFILIATIONS,
} from 'constants/Constants';
import { camelToSnake, validateURL, getFileName } from 'utils/UtilityFunctions';

interface PersonaInfo {
  firstName: string;
  lastName: string;
  bio: string;
  /* about: string; */
  careerCategories: Array<any>;
  opportunityCategories: Array<any>;
  phone1?: string;
  phone1Type?: string;
  phone2?: string;
  phone2Type: string;
  displayPronouns: string;
  preferredPronouns?: string;
  ideology?: string;
  partyAffiliation?: string;
  campaigns?: any;
  schools?: any;
}

interface Location {
  city: string;
  state: string;
  zip: number;
}

interface Websites {
  website: string;
  facebook: string;
  twitter: string;
  linkedin: string;
  instagram: string;
  /* additionalLink1: string;
  additionalLink2: string; */
  /* additionalLinks: Array<string>; */
}

const DEFAULT_SCHOOLS = [
  {
    name: '',
    notes: '',
    level: '',
  },
  {
    name: '',
    notes: '',
    level: '',
  },
  {
    name: '',
    notes: '',
    level: '',
  },
];

const DEFAULT_CAMPAIGNS = [
  {
    title: '',
    name: '',
    date_range: '',
  },
  {
    title: '',
    name: '',
    date_range: '',
  },
  {
    title: '',
    name: '',
    date_range: '',
  },
];

const USER_INFO_ERRORS: any = {
  firstName: 'Please enter your first name',
  lastName: 'Please enter your last name',
  bio: 'Please enter personal bio',
  /* about: 'Please enter something about yourself', */
  city: 'Please enter the city',
  state: 'Please enter the state',
  zip: 'Please enter the zip code',
  website: 'Please enter a valid website URL',
  partyAffiliation: 'Please enter the party affiliation',
};

const USER_DETAILS_VALIDATIONS: any = {
  firstName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  lastName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  userName: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 30',
  },
  bio: {
    min: 1,
    max: 5000,
    error: 'Please enter characters between 1 and 300',
  },
  city: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  state: {
    min: 1,
    max: 20,
    error: 'Please enter characters between 1 and 20',
  },
  zip: {
    min: 5,
    max: 6,
    error: 'Please enter characters between 5 and 6',
  },
};

const EditProfile = () => {
  const {
    userDetails: {
      id,
      first_name,
      last_name,
      bio: current_bio,
      photo: current_photo,
      /* about: current_about, */
      resume: current_resume,
      city: current_city,
      state: current_state,
      zip: current_zip,
      website: current_website,
      facebook: current_facebook,
      twitter: current_twitter,
      linkedin: current_linkedin,
      instagram: current_instagram,
      slug,
      phone1_type,
      phone2_type,
      phone1: phone_1,
      phone2: phone_2,
      display_pronouns,
      preferred_pronouns,
      ideology: current_ideology,
      party_affiliation,
    },
    setAuth,
  } = useContext(GlobalContext);

  // personal info state
  const [personalInfo, setPersonalInfo] = useState<PersonaInfo>({
    firstName: first_name,
    lastName: last_name,
    bio: current_bio,
    careerCategories: [],
    opportunityCategories: [],
    phone1: phone_1,
    phone2: phone_2,
    phone1Type: phone1_type,
    phone2Type: phone2_type,
    ideology: current_ideology,
    partyAffiliation: party_affiliation,
    displayPronouns: display_pronouns,
    preferredPronouns: preferred_pronouns,
    schools: [],
    campaigns: [],
    /* about: current_about, */
  });

  // location info state
  const [location, setLocation] = useState<Location>({
    city: current_city,
    state: current_state,
    zip: current_zip,
  });

  // websites info state
  const [websites, setWebsites] = useState<Websites>({
    website: current_website,
    facebook: current_facebook,
    twitter: current_twitter,
    linkedin: current_linkedin,
    instagram: current_instagram,
  });

  // additional Links
  const [additionalLinks, setadditionalLinks] = useState<any>({
    additionalLink1: { title: '', link: '' },
    additionalLink2: { title: '', link: '' },
  });

  const [validationErrors, setValidationErrors] = useState<any>({
    firstName: '',
    lastName: '',
    bio: '',
    categories: '',
    city: '',
    state: '',
    zip: '',
    website: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    instagram: '',
    additionalLink1Title: '',
    additionalLink2Title: '',
    additionalLink1: '',
    additionalLink2: '',
    displayPronouns: '',
    partyAffiliation: '',
    campaigns: '',
    schools: '',
  });

  const [photo, setPhoto] = useState<null | File>(null);

  const [categories, setCategories] = useState<Array<Category | any>>(null);

  const [resumeFile, setResumeFile] = useState<null | File>(null);

  const [newToken, setNewToken] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>('');

  const [fetchLoading, setFetchLoading] = useState<boolean>(false);

  const [currentResume, setCurrentResume] = useState<string>(
    current_resume
      ? current_resume.substr(current_resume.lastIndexOf('/') + 1)
      : ''
  );

  const handlePersonalInfoChange = (event: any) => {
    const { name, value } = event.target;

    if (!value && USER_INFO_ERRORS[name]) {
      setValidationErrors({
        ...validationErrors,
        [name]: USER_INFO_ERRORS[name],
      });
    } else {
      if (USER_DETAILS_VALIDATIONS[name]) {
        if (
          value.length > USER_DETAILS_VALIDATIONS[name].max ||
          value.length < USER_DETAILS_VALIDATIONS[name].min
        ) {
          setValidationErrors({
            ...validationErrors,
            [name]: USER_DETAILS_VALIDATIONS[name].error,
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [name]: '',
          });
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: '',
        });
      }
    }

    setPersonalInfo({
      ...personalInfo,
      [name]: value,
    });
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!value) {
      setValidationErrors({
        ...validationErrors,
        [name]: USER_INFO_ERRORS[name],
      });
    } else {
      if (USER_DETAILS_VALIDATIONS[name]) {
        if (
          value.length > USER_DETAILS_VALIDATIONS[name].max ||
          value.length < USER_DETAILS_VALIDATIONS[name].min
        ) {
          setValidationErrors({
            ...validationErrors,
            [name]: USER_DETAILS_VALIDATIONS[name].error,
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            [name]: '',
          });
        }
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: '',
        });
      }
    }

    setLocation({
      ...location,
      [name]: value,
    });
  };

  const handleWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (value) {
      if (validateURL(value)) {
        setValidationErrors({
          ...validationErrors,
          [name]: '',
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [name]: USER_INFO_ERRORS['website'],
        });
      }
    } else {
      setValidationErrors({
        ...validationErrors,
        [name]: '',
      });
    }

    setWebsites({
      ...websites,
      [name]: value,
    });
  };

  const handleAdditionalLinksChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;

    if (name === 'additionalLink1Title') {
      if (value) {
        if (!additionalLinks.additionalLink1.link) {
          setValidationErrors({
            ...validationErrors,
            additionalLink1: 'Please enter URL',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            additionalLink1: validateURL(additionalLinks.additionalLink1.link)
              ? ''
              : USER_INFO_ERRORS['website'],
            additionalLink1Title: '',
          });
        }
      } else {
        if (!additionalLinks.additionalLink1.link) {
          setValidationErrors({
            ...validationErrors,
            additionalLink1: '',
            additionalLink1Title: '',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            additionalLink1: validateURL(additionalLinks.additionalLink1.link)
              ? ''
              : USER_INFO_ERRORS['website'],
            additionalLink1Title: 'Please enter title',
          });
        }
      }

      setadditionalLinks({
        ...additionalLinks,
        additionalLink1: {
          ...additionalLinks.additionalLink1,
          title: value,
        },
      });
    }

    if (name === 'additionalLink1') {
      if (value) {
        if (!additionalLinks.additionalLink1.title) {
          setValidationErrors({
            ...validationErrors,
            additionalLink1Title: 'Please enter title',
            additionalLink1: validateURL(value)
              ? ''
              : USER_INFO_ERRORS['website'],
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            additionalLink1Title: '',
            additionalLink1: validateURL(value)
              ? ''
              : USER_INFO_ERRORS['website'],
          });
        }
      } else {
        if (!additionalLinks.additionalLink1.title) {
          setValidationErrors({
            ...validationErrors,
            additionalLink1Title: '',
            additionalLink1: '',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            additionalLink1Title: '',
            additionalLink1: 'Please enter URL',
          });
        }
      }

      setadditionalLinks({
        ...additionalLinks,
        additionalLink1: {
          ...additionalLinks.additionalLink1,
          link: value,
        },
      });
    }

    if (name === 'additionalLink2Title') {
      if (value) {
        if (!additionalLinks.additionalLink2.link) {
          setValidationErrors({
            ...validationErrors,
            additionalLink2: 'Please enter URL',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            additionalLink2: validateURL(additionalLinks.additionalLink2.link)
              ? ''
              : USER_INFO_ERRORS['website'],
            additionalLink2Title: '',
          });
        }
      } else {
        if (!additionalLinks.additionalLink2.link) {
          setValidationErrors({
            ...validationErrors,
            additionalLink2: '',
            additionalLink2Title: '',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            additionalLink2: validateURL(additionalLinks.additionalLink2.link)
              ? ''
              : USER_INFO_ERRORS['website'],
            additionalLink2Title: 'Please enter title',
          });
        }
      }

      setadditionalLinks({
        ...additionalLinks,
        additionalLink2: {
          ...additionalLinks.additionalLink2,
          title: value,
        },
      });
    }

    if (name === 'additionalLink2') {
      if (value) {
        if (!additionalLinks.additionalLink2.title) {
          setValidationErrors({
            ...validationErrors,
            additionalLink2Title: 'Please enter title',
            additionalLink2: validateURL(value)
              ? ''
              : USER_INFO_ERRORS['website'],
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            additionalLink2Title: '',
            additionalLink2: validateURL(value)
              ? ''
              : USER_INFO_ERRORS['website'],
          });
        }
      } else {
        if (!additionalLinks.additionalLink2.title) {
          setValidationErrors({
            ...validationErrors,
            additionalLink2Title: '',
            additionalLink2: '',
          });
        } else {
          setValidationErrors({
            ...validationErrors,
            additionalLink2Title: '',
            additionalLink2: 'Please enter URL',
          });
        }
      }

      setadditionalLinks({
        ...additionalLinks,
        additionalLink2: {
          ...additionalLinks.additionalLink2,
          link: value,
        },
      });
    }
  };

  const handlePhotoChange = (image: File | null) => {
    setPhoto(image);
  };

  const handleResumeChange = (resume: File | null) => {
    setCurrentResume('');
    setResumeFile(resume);
  };

  const handleIdeologyChange = (evt: any) => {
    const {
      target: { value, checked },
    } = evt;
    if (checked) {
      setPersonalInfo({
        ...personalInfo,
        ideology: personalInfo.ideology
          ? `${personalInfo.ideology},${value}`
          : value,
      });
    } else {
      const valuesArr = personalInfo.ideology
        .split(',')
        .filter((val) => val !== value);
      setPersonalInfo({
        ...personalInfo,
        ideology: valuesArr.join(','),
      });
    }
  };

  const handleCampaignChange = (event: any, index: number) => {
    const { name, value } = event.target;
    setPersonalInfo({
      ...personalInfo,
      campaigns: personalInfo.campaigns.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return { ...item, [name]: value };
      }),
    });
  };

  const handleSchoolChange = (event: any, index: number) => {
    const { name, value } = event.target;
    setPersonalInfo({
      ...personalInfo,
      schools: personalInfo.schools.map((item, i) => {
        if (i !== index) {
          return item;
        }
        return { ...item, [name]: value };
      }),
    });
  };

  const uploadPhoto = async () => {
    setLoading(true);
    let uploadImageResponse: any = null;
    let errorOccurred: any = null;
    if (photo) {
      const fileInfo = {
        name: getFileName(photo.name),
        type: photo.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        uploadImageResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: photo,
        });
        return { uploadImageResponse, url };
      } else if (error) {
        setApiError(`An error occurred while uploading photo: ${error}`);
        setLoading(false);
        errorOccurred = error;
        return { error };
      }
    }
    return { uploadImageResponse, error: errorOccurred };
  };

  const uploadResume = async () => {
    setLoading(true);
    if (resumeFile) {
      const fileInfo = {
        name: getFileName(resumeFile.name),
        type: resumeFile.type,
      };
      const { signedRequest, url, error } = await getUploadURLService(fileInfo);

      if (signedRequest && url) {
        const uploadResumeResponse = await fetch(signedRequest, {
          method: 'PUT',
          body: resumeFile,
        });
        return { uploadResumeResponse, url };
      } else if (error) {
        setApiError(`An error occurred while uploading photo: ${error}`);
        setLoading(false);
        return { error };
      }
    }
  };

  const updateProfile = async (photoURL?: string, resumeURL?: string) => {
    setLoading(true);
    const consolidatedRequestBody: any = {
      ...personalInfo,
      ...location,
      ...websites,
    };
    const reducer = (acc: any, current: string) => {
      const newObj: any = {};
      if (current !== 'additionalLink1' && current !== 'additionalLink2') {
        newObj[camelToSnake(current)] = consolidatedRequestBody[current];
      }

      return { ...acc, ...newObj };
    };
    let requestBody = Object.keys({
      ...personalInfo,
      ...location,
      ...websites,
      ...additionalLinks,
    }).reduce(reducer, {});
    if (!additionalLinks.additionalLink1 && !additionalLinks.additionalLink2) {
      requestBody.additional_links = [];
    } else {
      const links = [
        additionalLinks.additionalLink1,
        additionalLinks.additionalLink2,
      ];

      requestBody.additional_links = links.filter((l) => l.title && l.link);
      requestBody.additional_links = requestBody.additional_links.map(
        (item) => ({
          title: item.title,
          link: item.link.toLowerCase(),
        })
      );
    }

    requestBody.schools = requestBody.schools.filter(
      (l) => l.level && l.name && l.notes
    );

    requestBody.campaigns = requestBody.campaigns.filter(
      (l) => l.title && l.name && l.date_range
    );

    if (requestBody.twitter) {
      requestBody.twitter = requestBody.twitter.toLowerCase();
    }

    if (requestBody.website) {
      requestBody.website = requestBody.website.toLowerCase();
    }

    if (requestBody.linkedin) {
      requestBody.linkedin = requestBody.linkedin.toLowerCase();
    }

    if (requestBody.facebook) {
      requestBody.facebook = requestBody.facebook.toLowerCase();
    }

    if (requestBody.instagram) {
      requestBody.instagram = requestBody.instagram.toLowerCase();
    }

    if (current_resume && !currentResume && !resumeFile) {
      requestBody = { ...requestBody, resume: '' };
    }

    if (resumeURL) {
      requestBody = { ...requestBody, resume: resumeURL };
    }

    const { token, error } = await updateUserAccountInfoService(id, {
      ...requestBody,
      career_categories: personalInfo.careerCategories
        ?.map((c) => c.name)
        .join(','),
      opportunity_categories: personalInfo.opportunityCategories
        ?.map((c) => c.name)
        .join(','),
      photo: photoURL,
    });

    if (token) {
      setNewToken(token);
      setAuth(token);
    } else if (error) {
      setApiError(error);
    }

    setLoading(false);
  };

  const handleSubmit = async () => {
    setApiError('');
    setNewToken('');
    if (photo && resumeFile) {
      // first try uploading photo and then upload resume
      const {
        uploadImageResponse,
        url: photoUrl,
        error,
      }: any = await uploadPhoto();
      if (uploadImageResponse) {
        const {
          uploadResumeResponse,
          url: resumeUrl,
          error,
        }: any = await uploadResume();
        if (uploadResumeResponse) {
          updateProfile(photoUrl, resumeUrl);
        } else if (error) {
          return;
        }
      } else if (error) {
        return;
      }
    } else if (photo && !resumeFile) {
      const { uploadImageResponse, url: photoUrl }: any = await uploadPhoto();
      if (uploadImageResponse) {
        updateProfile(photoUrl, '');
      }
    } else if (!photo && resumeFile) {
      const {
        uploadResumeResponse,
        url: resumeUrl,
      }: any = await uploadResume();
      if (uploadResumeResponse) {
        updateProfile('', resumeUrl);
      }
    } else {
      updateProfile('', '');
    }
  };

  const handleCategorySelect = (categoryList: Array<Category>, key) => {
    /* const selectedCategories = categoryList.map((list) => ({ name: list })); */

    setPersonalInfo({
      ...personalInfo,
      [key]: categoryList,
    });
  };

  const isSubmitDisabled = (): boolean => {
    const {
      firstName,
      lastName,
      displayPronouns,
      partyAffiliation,
    } = personalInfo;
    const { city, state, zip } = location;

    if (
      firstName &&
      lastName &&
      city &&
      state &&
      zip &&
      displayPronouns &&
      partyAffiliation &&
      Object.keys(validationErrors).every((e) => !validationErrors[e])
    ) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      const { user } = await fetchUserDetailsService(id);

      if (user) {
        setPersonalInfo((personalInfo) => ({
          ...personalInfo,
          bio: user.bio,
          careerCategories: user.career_categories?.length
            ? user.career_categories.split(',').map((k) => ({ name: k }))
            : [],
          opportunityCategories: user.opportunity_categories?.length
            ? user.opportunity_categories.split(',').map((k) => ({ name: k }))
            : [],
          schools: DEFAULT_SCHOOLS.map(
            (school, i) => user.schools[i] || school
          ),
          campaigns: DEFAULT_CAMPAIGNS.map(
            (campaign, i) => user.campaigns[i] || campaign
          ),
        }));

        if (user.additional_links) {
          setadditionalLinks((additionalLinks: any) => ({
            ...additionalLinks,
            additionalLink1: user.additional_links[0] || {
              title: '',
              link: '',
            },
            additionalLink2: user.additional_links[1] || {
              title: '',
              link: '',
            },
          }));
        }

        if (user.resume) {
          setCurrentResume(
            user.resume.substr(user.resume.lastIndexOf('/') + 1)
          );
        }
      }
    };

    const fetchCategories = async () => {
      setFetchLoading(true);
      const categories: Array<Category> = await fetchCategoriesService();

      if (categories && categories.length) {
        setCategories(categories);
        await fetchUserDetails();
      }

      setFetchLoading(false);
    };

    fetchCategories();
  }, [id]);

  const renderTooltipCost = (props: any) => (
    <Tooltip
      className={styles.tooltipContainer}
      id="button-tooltipCost"
      {...props}
    >
      Categories are how we sort Careers and Opportunities, and also how users
      display their skills on their profiles. Select the Categories you want to
      showcase for hiring/getting hired.
    </Tooltip>
  );
  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        {fetchLoading ? <Loader /> : null}
        {categories && (
          <Row>
            <Col xl={9} lg={9} md={12} sm={12}>
              <Row>
                <Col>
                  <div className={styles.sectionHeading}>Edit Profile</div>
                  <div className={styles.profileInfo}>
                    Personal Information
                    <span>Basic Information</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>First Name</div>
                    <Input
                      name="firstName"
                      type="text"
                      value={personalInfo.firstName}
                      onChange={handlePersonalInfoChange}
                      className={
                        validationErrors.firstName ? styles.errorInput : ''
                      }
                    />
                    {validationErrors.firstName && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.firstName}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Last Name</div>
                    <Input
                      name="lastName"
                      type="text"
                      className={
                        validationErrors.lastName ? styles.errorInput : ''
                      }
                      value={personalInfo.lastName}
                      onChange={handlePersonalInfoChange}
                    />
                    {validationErrors.lastName && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.lastName}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Phone 1 Type</div>
                    <Select
                      id="phone1Type"
                      selectItems={PHONE_TYPES}
                      onChange={handlePersonalInfoChange}
                      name="phone1Type"
                      value={personalInfo.phone1Type}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Phone Number</div>
                    <Input
                      name="phone1"
                      type="text"
                      value={personalInfo.phone1}
                      onChange={handlePersonalInfoChange}
                      placeholder="(___) ___ - ____"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Phone 2 Type</div>
                    <Select
                      id="phone2Type"
                      selectItems={PHONE_TYPES}
                      onChange={handlePersonalInfoChange}
                      name="phone2Type"
                      value={personalInfo.phone2Type}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Phone Number</div>
                    <Input
                      name="phone2"
                      type="text"
                      value={personalInfo.phone2}
                      onChange={handlePersonalInfoChange}
                      placeholder="(___) ___ - ____"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Display Pronouns
                      <span className={styles.requiredField}> *</span>
                    </div>
                    <Select
                      id="displayPronouns"
                      selectItems={DISPLAY_PRONOUNS}
                      onChange={handlePersonalInfoChange}
                      name="displayPronouns"
                      value={personalInfo.displayPronouns}
                    />
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Preferred Pronouns</div>
                    <Input
                      name="preferredPronouns"
                      type="text"
                      value={personalInfo.preferredPronouns}
                      onChange={handlePersonalInfoChange}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>About</div>
                    <TextArea
                      name="bio"
                      value={personalInfo.bio}
                      className={validationErrors.bio ? styles.errorInput : ''}
                      onChange={handlePersonalInfoChange}
                    />
                    {validationErrors.bio && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.bio}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className={`${styles.authInput} ${styles.authInputMultiselect}`}
                  >
                    <div className={styles.authLabel}>
                      Jobs:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        What type of jobs/services are you searching for?
                      </span>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipCost}
                      >
                        <div className={styles.infoHover}>i</div>
                      </OverlayTrigger>
                      <span className={styles.shortMess}>
                        Select all that apply, and
                        <a
                          href="https://capitolcommunity.activehosted.com/f/5"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          click here{' '}
                        </a>
                        if your type is not listed.
                      </span>
                    </div>
                    <Multiselect
                      options={categories || []}
                      isObject={true}
                      displayValue="name"
                      showCheckbox
                      selectedValues={personalInfo.careerCategories}
                      onSelect={(categories) =>
                        handleCategorySelect(categories, 'careerCategories')
                      }
                      onRemove={(categories) =>
                        handleCategorySelect(categories, 'careerCategories')
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div
                    className={`${styles.authInput} ${styles.authInputMultiselect}`}
                  >
                    <div className={styles.authLabel}>
                      Services:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        What should people hire/contact you for?
                      </span>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltipCost}
                      >
                        <div className={styles.infoHover}>i</div>
                      </OverlayTrigger>
                      <span className={styles.shortMess}>
                        Select all that apply, and
                        <a
                          href="https://capitolcommunity.activehosted.com/f/5"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          click here{' '}
                        </a>
                        if your type is not listed.
                      </span>
                    </div>
                    <Multiselect
                      options={categories || []}
                      isObject={true}
                      displayValue="name"
                      showCheckbox
                      selectedValues={personalInfo.opportunityCategories}
                      onSelect={(categories) =>
                        handleCategorySelect(
                          categories,
                          'opportunityCategories'
                        )
                      }
                      onRemove={(categories) =>
                        handleCategorySelect(
                          categories,
                          'opportunityCategories'
                        )
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Political
                    <span>
                      To help connect you with jobs, services and like-minded
                      professionals.
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Party Affiliation
                      <span className={styles.requiredField}> *</span>
                    </div>
                    <Select
                      id="partyAffiliation"
                      selectItems={AFFILIATIONS}
                      onChange={handlePersonalInfoChange}
                      name="partyAffiliation"
                      value={personalInfo.partyAffiliation}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>
                      Ideology:{' '}
                      <span style={{ fontWeight: 'normal' }}>
                        Who you work with (choose all that apply)
                      </span>
                      <span className={styles.requiredField}> *</span>
                    </div>
                    <Checkbox
                      id="conservative"
                      name="ideology"
                      isChecked={
                        personalInfo.ideology.indexOf('Conservative') > -1
                      }
                      onChange={handleIdeologyChange}
                      label="Conservative"
                      value="Conservative"
                    />
                    <Checkbox
                      id="moderate"
                      name="ideology"
                      isChecked={personalInfo.ideology.indexOf('Moderate') > -1}
                      onChange={handleIdeologyChange}
                      label="Moderate"
                      value="Moderate"
                    />
                    <Checkbox
                      id="progressive"
                      name="ideology"
                      isChecked={
                        personalInfo.ideology.indexOf('Progressive') > -1
                      }
                      onChange={handleIdeologyChange}
                      label="Progressive"
                      value="Progressive"
                    />
                    <Checkbox
                      id="prefer_not_to_answer"
                      name="ideology"
                      isChecked={
                        personalInfo.ideology.indexOf('Prefer not to answer') >
                        -1
                      }
                      onChange={handleIdeologyChange}
                      label="Prefer not to answer"
                      value="Prefer not to answer"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Experience
                    <span>
                      To help connect you with the best jobs and services
                      available. Include any information outside of these fields
                      in your About section.
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  {personalInfo.campaigns?.map((item, i) => {
                    return (
                      <Row key={i}>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <div className={styles.subHeading}>
                            Campaign/Company #{i + 1}
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <div className={styles.authInput}>
                            <div className={styles.authLabel}>Name</div>
                            <Input
                              name="name"
                              type="text"
                              value={personalInfo.campaigns[i].name}
                              onChange={(e) => handleCampaignChange(e, i)}
                            />
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <div className={styles.authInput}>
                            <div className={styles.authLabel}>Title</div>
                            <Input
                              name="title"
                              type="text"
                              value={personalInfo.campaigns[i].title}
                              onChange={(e) => handleCampaignChange(e, i)}
                            />
                          </div>
                        </Col>
                        <Col xl={8} lg={8} md={12} sm={12}>
                          <div className={styles.authInput}>
                            <div className={styles.authLabel}>Date Range</div>
                            <Input
                              name="date_range"
                              type="text"
                              value={personalInfo.campaigns[i].date_range}
                              onChange={(e) => handleCampaignChange(e, i)}
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Education
                    <span>
                      To help connect you with the best jobs and services
                      available. Include any information outside of these fields
                      in your About section.
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12}>
                  {personalInfo.schools?.map((item, i) => {
                    return (
                      <Row key={i}>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <div className={styles.subHeading}>
                            School #{i + 1}
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <div className={styles.authInput}>
                            <div className={styles.authLabel}>
                              Level{' '}
                              <span style={{ fontWeight: 'normal' }}>
                                (Degree, etc)
                              </span>
                            </div>
                            <Input
                              name="level"
                              type="text"
                              value={personalInfo.schools[i].level}
                              onChange={(e) => handleSchoolChange(e, i)}
                            />
                          </div>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <div className={styles.authInput}>
                            <div className={styles.authLabel}>
                              Institution Name
                            </div>
                            <Input
                              name="name"
                              type="text"
                              value={personalInfo.schools[i].name}
                              onChange={(e) => handleSchoolChange(e, i)}
                            />
                          </div>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <div className={styles.authInput}>
                            <div className={styles.authLabel}>
                              Notes{' '}
                              <span style={{ fontWeight: 'normal' }}>
                                (Field of Study, Honors, etc)
                              </span>
                            </div>
                            <Input
                              name="notes"
                              type="text"
                              value={personalInfo.schools[i].notes}
                              onChange={(e) => handleSchoolChange(e, i)}
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Location
                    <span>To help find better matches.</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>City</div>
                    <Input
                      name="city"
                      type="text"
                      className={validationErrors.city ? styles.errorInput : ''}
                      value={location.city}
                      onChange={handleLocationChange}
                    />
                    {validationErrors.city && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.city}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>State</div>
                    <Select
                      id="state"
                      selectItems={STATES}
                      onChange={handleLocationChange}
                      name="state"
                      value={location.state}
                    />
                    {validationErrors.state && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.state}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
                <Col xl={6} lg={6} md={12} sm={12}>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Zip</div>
                    <Input
                      name="zip"
                      type="text"
                      className={validationErrors.zip ? styles.errorInput : ''}
                      value={location.zip}
                      onChange={handleLocationChange}
                    />
                    {validationErrors.zip && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.zip}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="fileUploadWrap">
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Upload Your Photo</div>
                    <FileUpload
                      fileType={FILE_TYPE.IMAGE}
                      fileSpecs={[
                        '5mb max',
                        '500px wide',
                        '500px tall',
                        'Capitol Community logo is used if no image',
                      ]}
                      onFileChange={handlePhotoChange}
                      alreadyUploadedFileURL={current_photo}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Add Additional Links
                    <span>Personal or Business.</span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Website</div>
                    <Input
                      name="website"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.website ? styles.errorInput : ''
                      }
                      value={websites.website}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.website && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.website}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Facebook</div>
                    <Input
                      name="facebook"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.facebook ? styles.errorInput : ''
                      }
                      value={websites.facebook}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.facebook && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.facebook}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Twitter</div>
                    <Input
                      name="twitter"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.twitter ? styles.errorInput : ''
                      }
                      value={websites.twitter}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.twitter && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.twitter}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>linkedin</div>
                    <Input
                      name="linkedin"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.linkedin ? styles.errorInput : ''
                      }
                      value={websites.linkedin}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.linkedin && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.linkedin}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Instagram</div>
                    <Input
                      name="instagram"
                      type="text"
                      placeholder="http://"
                      className={
                        validationErrors.instagram ? styles.errorInput : ''
                      }
                      value={websites.instagram}
                      onChange={handleWebsiteChange}
                    />
                    {validationErrors.instagram && (
                      <Alert variant="danger" className={styles.apiError}>
                        <div className={styles.error}>
                          {validationErrors.instagram}
                        </div>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Additional Links</div>
                    <Row className="mb-4">
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink1Title"
                          type="text"
                          placeholder="Title"
                          className={
                            validationErrors.additionalLink1Title
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink1.title}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink1Title && (
                          <Alert variant="danger" className={styles.apiError}>
                            <div className={styles.error}>
                              {validationErrors.additionalLink1Title}
                            </div>
                          </Alert>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink1"
                          type="text"
                          placeholder="http://"
                          className={
                            validationErrors.additionalLink1
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink1.link}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink1 && (
                          <Alert variant="danger" className={styles.apiError}>
                            <div className={styles.error}>
                              {validationErrors.additionalLink1}
                            </div>
                          </Alert>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={4} lg={4} md={12} sm={12}>
                        <Input
                          name="additionalLink2Title"
                          type="text"
                          placeholder="Title"
                          className={
                            validationErrors.additionalLink2Title
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink2.title}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink2Title && (
                          <Alert variant="danger" className={styles.apiError}>
                            <div className={styles.error}>
                              {validationErrors.additionalLink2Title}
                            </div>
                          </Alert>
                        )}
                      </Col>
                      <Col xl={8} lg={8} md={12} sm={12}>
                        <Input
                          name="additionalLink2"
                          type="text"
                          placeholder="http://"
                          className={
                            validationErrors.additionalLink2
                              ? styles.errorInput
                              : ''
                          }
                          value={additionalLinks.additionalLink2.link}
                          onChange={handleAdditionalLinksChange}
                        />
                        {validationErrors.additionalLink2 && (
                          <Alert variant="danger" className={styles.apiError}>
                            <div className={styles.error}>
                              {validationErrors.additionalLink2}
                            </div>
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.profileInfo}>
                    Upload Your Resume
                    <span>
                      Be careful about sharing information like personal emails
                      or phone numbers. Onsite messaging is available for all
                      users.
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authInput}>
                    <div className={styles.authLabel}>Upload Your Resume</div>
                    <FileUpload
                      fileType={FILE_TYPE.DOCUMENT}
                      fileSpecs={['pdf and doc files only', '5mbs max']}
                      onFileChange={handleResumeChange}
                      currentUploadedFileName={currentResume}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={styles.authButton}>
                    <Button
                      label={loading ? 'loading...' : 'Save All Information'}
                      type="large w-100"
                      disabled={isSubmitDisabled()}
                      onClick={handleSubmit}
                    />
                  </div>
                  {apiError && (
                    <Alert variant="danger">
                      <div className={styles.error}>{apiError}</div>
                    </Alert>
                  )}
                  {newToken ? (
                    <Redirect to={`${ROUTES.PROFILE}/${slug}`} />
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
};

export default EditProfile;
