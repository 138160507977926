import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';

//Components
//import Sidebar from '../Sidebar/Sidebar';

// SCSS
import styles from './Welcome.module.scss';

const Welcome = () => {
  return (
    <Fragment>
      <Row className="justify-content-md-center">
        <Col md={9} sm={12}>
          <div className={styles.mainHeading}>
            Welcome to your professional political network.
          </div>
          <p>
            Capitol Community is a bipartisan platform aimed at helping
            democratize access to the political industry. Whether you are
            looking to hire campaign staff, find an internship, take courses,
            earn experiential credentials, or provide professional services in
            politics, we are here to help you further your career.
          </p>
          <p>
            Capitol Community was developed by top political experts, with
            decades of experience advising Presidential administrations, state
            elections, and local campaigns. Our bipartisan team aims to provide
            equal tools and resources to the entire political industry, free of
            bias.
          </p>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Welcome;
