import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Container, Row, Col, Nav, Tab, Alert } from 'react-bootstrap';
import qs from 'qs';

// Types
import CareerType from './components/Career/Career';
import OpportunityType from './components/Opportunity/Opportunity';
import { Job } from 'interfaces/Job.types';
import { Category } from 'interfaces/Category.types';

// Components
import Loader from 'components/Loader/Loader';

// Services
import { fetchJobDetailsService } from 'services/JobService';
import { fetchCategoriesService } from 'services/CommonService';

// SCSS
import styles from './Post.module.scss';

const Post: React.FC = () => {
  const { id, type } = useParams();
  const { id: idQuery, type: typeQuery } = qs.parse(useLocation().search, {
    ignoreQueryPrefix: true,
  });
  const [job, setJob] = useState<Job | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [categories, setCategories] = useState<Array<Category | any>>([]);

  const fetchCategories = useCallback(async () => {
    const categories: Array<Category> = await fetchCategoriesService();

    if (categories && categories.length) {
      setCategories(categories);
    }
  }, []);

  useEffect(() => {
    fetchCategories();
    (async function () {
      if (id || idQuery) {
        setIsLoading(true);
        const { jobDetails, error } = await fetchJobDetailsService(
          id || idQuery
        );
        if (jobDetails) {
          setJob({
            ...jobDetails,
            category: [{ name: jobDetails.category.split(',')[0] }],
          });
        }
        if (error) {
          setError(error);
        }
        setIsLoading(false);
      }
    })();
  }, [id, idQuery, fetchCategories]);

  return (
    <Container className={styles.postJob} fluid>
      <Container className="p-0">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className={styles.postMainTitle}>
              {!id && !idQuery
                ? 'Let us help you find applicants or get hired.'
                : ''}
              {idQuery ? `Relist ${typeQuery}` : ''}
              {id ? `Edit ${type}` : ''}
            </div>
            {!id && !idQuery && (
              <div className={styles.postSubTitle}>
                All listings are free to post and apply, and include our basic
                matchmaking algorithm at no cost. Additional premium promotional
                services are available within your post setup.
              </div>
            )}
          </Col>
          <Col>
            <Tab.Container
              id="job-board-post-form"
              defaultActiveKey={type || typeQuery}
            >
              <Row>
                {/* Type Switcher */}
                {!id && !idQuery && (
                  <Col
                    className={styles.typeSwitcher}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                  >
                    <div className={styles.postNavLabel}>
                      What type of position is it?
                    </div>
                    <Nav
                      variant="tabs"
                      className={`flex-column ${styles.postTabs}`}
                    >
                      <Row>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <Nav.Item>
                            <Nav.Link
                              className={styles.postTab}
                              eventKey="career"
                            >
                              <div className={styles.postTabTitle}>Job</div>
                              <div className={styles.postTabDesc}>
                                Campaigns/businesses can create a listing for
                                any type of full-time or contract work,
                                internship, etc. All job posts are free to
                                create, apply and include our matchmaking
                                service. (Additional promotional services are
                                available at the bottom of the page).
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Col>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <Nav.Item>
                            <Nav.Link
                              className={styles.postTab}
                              eventKey="opportunity"
                            >
                              <div className={styles.postTabTitle}>Service</div>
                              <div className={styles.postTabDesc}>
                                Services are listings from our professional
                                community, detailing skills that are available
                                for hire (free and paid). Our community creates
                                unique Service listings for each of their
                                skills, all of which are searchable. Anyone
                                looking to be hired should post a Service.
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Col>
                      </Row>
                    </Nav>
                  </Col>
                )}

                {/* Type Form Post */}
                {(!id && !idQuery) || job ? (
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <Tab.Content>
                      {/* Career */}
                      <Tab.Pane eventKey="career">
                        <CareerType
                          id={id || idQuery}
                          job={job}
                          categories={categories}
                          relist={!!idQuery}
                        />
                      </Tab.Pane>

                      {/* Opportunity */}
                      <Tab.Pane eventKey="opportunity">
                        <OpportunityType
                          id={id || idQuery}
                          job={job}
                          categories={categories}
                          relist={!!idQuery}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                ) : (
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <Tab.Content>
                      {/* Career */}
                      <Tab.Pane eventKey={type || typeQuery}>
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <div className={styles.jobSubmitError}>
                            <Alert
                              variant={'danger'}
                              className={styles.apiError}
                            >
                              {error}
                            </Alert>
                          </div>
                        )}
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                )}
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Post);
