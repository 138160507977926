import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { MIC_CONTACT_EMAIL } from 'constants/Constants';

// SCSS
import styles from './Terms.module.scss';

const Terms = () => {
  return (
    <div className={styles.supportPage}>
      <Container className="p-0">
        <Row>
          <Col md={12}>
            <div className={styles.termsContainer}>
              <p className="c5">
                <span className="c0">Terms of Service</span>
              </p>
              <p className="c1" id="h.gjdgxs">
                <span className="c0">
                  Welcome to Capitol Community (the “Website”). The Website and
                  services and products offered through the Website, including
                  articles, reference materials, communication tools, message
                  boards and search services (the “Service”), are owned and
                  operated by Capitol Community Inc.. When we refer to “us,”
                  “our,” “we” and words of similar import, we mean Capitol
                  Community Inc.. Reference to “Example” means the Website and
                  the Service, or either of them as the context implies.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  Access to non-public areas of the Service are provided in
                  accordance with the following terms (“Terms”). By using
                  Example, you electronically agree to be legally bound by the
                  Terms, which govern your use of Example. If you do not agree
                  to all of these Terms, please immediately discontinue any
                  further use of Example. Your failure to follow the Terms may
                  result in suspension or termination of your access to Example.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  We may change these Terms at any time. The most current
                  version will be available by clicking on a link at the bottom
                  of the first page on our Website. Continued access to Example
                  by you will constitute your acceptance of any changes or
                  revisions to the Terms.
                </span>
              </p>
              <p className="c3">
                <span className="c0">Passwords</span>
              </p>
              <p className="c1">
                <span className="c0">
                  Your right to use Example is personal to you. You may not
                  sub-license, transfer, sell or assign your right to access or
                  use Example to any third party without prior our written
                  approval. Any attempt to do so will be null and void and shall
                  be considered a material breach of the Terms.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  You will be solely responsible for maintaining the
                  confidentiality of your password. You are solely responsible
                  for all usage or activity on your account including, but not
                  limited to, use of the account by any person who uses your ID
                  and password. If you have reason to believe that your account
                  is no longer secure (for example, in the event of a loss,
                  theft, or unauthorized disclosure or use of your ID, password
                  or any credit, debit or charge card number stored on the
                  Website), you must promptly change your password and notify us
                  by contacting us at the following address:{' '}
                  <a href={`mailto:${MIC_CONTACT_EMAIL}`}>
                    {MIC_CONTACT_EMAIL}.
                  </a>
                </span>
              </p>
              <p className="c3">
                <span className="c0">General Usage Rules</span>
              </p>
              <p className="c1">
                <span className="c0">
                  You agree not to disrupt, modify or interfere with Example, or
                  its associated software, hardware, and/or servers in any way,
                  and you agree not to impede or interfere with others’ use of
                  the Website. You further agree not to alter or tamper with any
                  information or materials on or associated with Example.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  Other than connecting to our service provider&rsquo;s servers
                  by http requests using a web browser, you may not attempt to
                  gain access to our service provider&rsquo;s servers by any
                  means.
                </span>
              </p>
              <p className="c3">
                <span className="c0">Intellectual Property</span>
              </p>
              <p className="c1">
                <span className="c0">
                  You acknowledge and agree that Example and the information,
                  content and software presented to you through or by Example or
                  used in connection with Example contain proprietary and
                  confidential information that is protected under U.S. and
                  international intellectual property laws, including those
                  pertaining to the protection of copyrights, trademarks,
                  service marks, and patents, and security components that
                  protect digital information. Except as expressly authorized by
                  us, you agree not to sell, rewrite, modify, redistribute,
                  create derivative works, or rent any part of Example or any
                  information presented to you through Example, in whole or in
                  part.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  You may look at Example online, download individual materials
                  to your personal or handheld computer for later reading, and
                  even print a copy of materials for yourself. You may not
                  remove any copyright notices from our materials. We reserve
                  all of our other rights not granted in these Terms. You agree
                  not to access Example by any means other than through the
                  interface that is provided by us for use in accessing Example.
                </span>
              </p>
              <p className="c3">
                <span className="c0">License</span>
              </p>
              <p className="c1">
                <span className="c0">
                  We grant you a personal, non-exclusive, non-transferable,
                  limited and revocable license to use Example subject to the
                  Terms. You may not use Example in a manner that exceeds the
                  rights granted for your use of Example, which includes
                  unauthorized copying or distribution of any of the content
                  displayed or used in Example or creating an unauthorized
                  derivative work.
                </span>
              </p>
              <p className="c3">
                <span className="c0">Information Provided by You</span>
              </p>
              <p className="c1">
                <span className="c0">
                  If you upload materials, content or information (collectively,
                  &ldquo;Information&rdquo;) to areas of Example that are
                  available to other users, you give us an irrevocable,
                  perpetual license to use, reproduce, modify, adapt, publicly
                  perform and publicly display the Information. Please refer to
                  our Privacy Policy for an explanation of how we use your
                  information and your rights to change or delete it. A link to
                  our Privacy Policy is provided at the bottom of these Terms.
                </span>
              </p>
              <p className="c3">
                <span className="c0">Restrictions Applicable to Use</span>
              </p>
              <p className="c1">
                <span className="c0">
                  In connection with your use of Example, you may not post,
                  email, transmit or otherwise distribute: (a) Information
                  infringing on intellectual property or privacy rights of
                  others; Information that is unlawful, harmful, obscene,
                  defamatory, harassing, abusive, or slanderous in our sole
                  opinion; (b) Information that harm minors in any way;
                  advertisements or other commercial messages; (c) viruses or
                  other harmful computer code designed to interrupt, destroy or
                  limit the use of any computer software or hardware, or (d)
                  confidential information belonging to any other person. In
                  addition, you may not engage in any conduct to: (a) collect
                  information about others; (b) interfere with Example or the
                  servers or other technology hardware used by Example; (c)
                  inhibit others from using Example, (d) allow any other person
                  or entity to use your password or other identification; or (e)
                  violate any law or regulation.
                </span>
              </p>
              <p className="c3">
                <span className="c0">Disclaimers of Warranties.</span>
              </p>
              <p className="c1">
                <span className="c0">
                  WE PROVIDE EXAMPLE &ldquo;AS IS&rdquo;, &ldquo;WITH ALL
                  FAULTS&rdquo; AND &ldquo;AS AVAILABLE.&rdquo; WE MAKE NO
                  EXPRESS WARRANTIES OR GUARANTEES ABOUT EXAMPLE. TO THE EXTENT
                  PERMITTED BY LAW, WE DISCLAIM IMPLIED WARRANTIES THAT EXAMPLE
                  IS MERCHANTABLE, OF SATISFACTORY QUALITY, ACCURATE, FIT FOR A
                  PARTICULAR PURPOSE OR NEED, OR NON-INFRINGING. WE DO NOT
                  GUARANTEE THAT THE RESULTS THAT MAY BE OBTAINED FROM THE USE
                  OF EXAMPLE, INCLUDING ANY INFORMATION OR CONTENT OBTAINED AS A
                  RESULT OF USING EXAMPLE, WILL BE EFFECTIVE, RELIABLE,
                  ACCURATE, OR MEET YOUR REQUIREMENTS. WE DO NOT GUARANTEE THAT
                  YOU WILL BE ABLE TO ACCESS OR USE EXAMPLE (EITHER DIRECTLY OR
                  THROUGH THIRD-PARTY NETWORKS) AT TIMES OR LOCATIONS OF YOUR
                  CHOOSING. NO ORAL OR WRITTEN INFORMATION OR ADVICE GIVEN BY
                  OUR REPRESENTATIVES SHALL CREATE A WARRANTY. YOU MAY HAVE
                  ADDITIONAL CONSUMER RIGHTS UNDER YOUR LOCAL LAWS THAT THIS
                  CONTRACT CANNOT CHANGE. YOU USE EXAMPLE AT YOUR OWN RISK.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  WE SHALL NOT BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
                  CONSEQUENTIAL OR EXEMPLARY DAMAGES ARISING FROM YOUR USE OF
                  THE SERVICE. THESE EXCLUSIONS APPLY TO ANY CLAIMS FOR LOST
                  PROFITS, LOST DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER
                  FAILURE OR MALFUNCTION, OR ANY OTHER COMMERCIAL DAMAGES OR
                  LOSSES, EVEN IF WE OR OUR SERVICE PROVIDERS KNEW OR SHOULD
                  HAVE KNOWN THE POSSIBILITY OF SUCH DAMAGES. BECAUSE SOME
                  STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE
                  LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL
                  DAMAGES, IN SUCH STATES OR JURISDICTIONS, THE SERVICE
                  PROVIDERS&rsquo; LIABILITY IN SUCH STATE OR JURISDICTION IS
                  LIMITED TO THE EXTENT PERMITTED BY LAW.
                </span>
              </p>
              <p className="c3">
                <span className="c0">Indemnity</span>
              </p>
              <p className="c1">
                <span className="c0">
                  You agree to defend, indemnify, and hold us, our officers,
                  directors, employees, agents, licensors, and suppliers,
                  harmless from and against any claims, actions or demands,
                  liabilities and settlements including without limitation,
                  reasonable legal and related fees and expenses, resulting
                  from, or alleged to result from, your violation of these
                  Terms.
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  Notice and Take Down Procedures and Copyright Agent
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  If you believe any materials accessible on or from Example
                  infringe your copyright, trademark or other legal rights, you
                  may request removal of those materials (or access thereto)
                  from Example by contacting us at{' '}
                  <a href={`mailto:${MIC_CONTACT_EMAIL}`}>
                    {MIC_CONTACT_EMAIL}.
                  </a>
                </span>
              </p>
              <p className="c3">
                <span className="c0">Termination and Modification</span>
              </p>
              <p className="c1">
                <span className="c0">
                  You agree that we may without prior notice, discontinue,
                  temporarily or permanently, Example (or any part thereof) or
                  eliminate your account, any associated email address, and
                  remove any information you uploaded or provided to Example
                  with or without notice. Cause for termination shall include,
                  but not be limited to, (a) violations of these Terms or your
                  Subscription Agreement, (b) requests by law enforcement or
                  other government agencies, (c) a request by you (d)
                  discontinuance or material modification to Example, or (e)
                  unexpected technical or security issues or problems.
                </span>
              </p>
              <p className="c3">
                <span className="c0">Entire Agreement</span>
              </p>
              <p className="c1">
                <span className="c0">
                  The Terms and any Subscriber Agreement, and the policies,
                  rules and guidelines posted on the Website constitute the
                  entire contract between you and us and supersede all previous
                  written or oral contracts. If any part of the Terms is held
                  invalid or unenforceable, that portion shall be construed in a
                  manner consistent with applicable law to reflect, as nearly as
                  possible, the original intentions of the parties, and the
                  remaining portions shall remain in full force and effect.
                </span>
              </p>
              <p className="c3">
                <span className="c0">
                  Choice of Law and Place for Resolving Disputes
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  The law of the State of Delaware governs this contract and any
                  claim or dispute that you may have against us, without regard
                  to its conflict of laws rules, and the United Nations
                  Convention on Contracts for the International Sale of Goods
                  shall have no applicability. You further agree that any
                  disputes or claims that you may have against us will be
                  exclusively resolved by a court located in Wilmington,
                  Delaware. You irrevocably consent to the venue and
                  jurisdiction of such courts.
                </span>
              </p>
              <p className="c1">
                <span className="c0">
                  BY AGREEING TO THIS AGREEMENT, YOU ARE: (1) WAIVING CLAIMS
                  THAT YOU MIGHT OTHERWISE HAVE AGAINST US BASED ON THE LAWS OF
                  OTHER JURISDICTIONS, INCLUDING YOUR OWN; (2) IRREVOCABLY
                  CONSENTING TO THE EXCLUSIVE JURISDICTION OF, AND VENUE IN,
                  STATE AND FEDERAL COURTS IN WILMINGTON, DELAWARE OVER ANY
                  DISPUTES OR CLAIMS YOU HAVE WITH US; AND (3) SUBMITTING
                  YOURSELF TO THE PERSONAL JURISDICTION OF COURTS LOCATED IN
                  WILMINGTON, DELAWARE FOR THE PURPOSE OF RESOLVING ANY SUCH
                  DISPUTES OR CLAIMS
                </span>
              </p>
              <p className="c3">
                <span className="c0">Assignments</span>
              </p>
              <p className="c1">
                <span className="c0">
                  We may assign this contract at any time without notice to you.
                  You may not assign this contract to anyone else.
                </span>
              </p>
              <p className="c3">
                <span className="c0">Execution of Agreement</span>
              </p>
              <p className="c1">
                <span className="c0">
                  Your continued use of Example constitutes your acceptance of
                  these Terms as a legal contract and the equivalent of an
                  electronic signature. Natural persons executing this contract
                  warrant and represent that they are at least eighteen (18)
                  years of age. Users and the person executing this Agreement on
                  behalf of any user that is a proprietorship, corporation,
                  partnership or other entity, represent that such Person is
                  duly authorized by pall necessary and appropriate corporate or
                  other action to execute this contract on behalf of the user.
                </span>
              </p>
              <p className="c8">
                <span className="c2"></span>
              </p>
              <div>
                <p className="c4">
                  <span className="c2"></span>
                </p>
                <p className="c7">
                  <span className="c2"></span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Terms;
