import React, { Fragment, useState, useEffect, useCallback } from 'react';

import { Link } from 'react-router-dom';

import { Row, Col, Alert } from 'react-bootstrap';

import orderBy from 'lodash/orderBy';

// Components
import Applicants from '../../../JobBoard/Applicants/Applicants';
import Loader from 'components/Loader/Loader';

// Constants
import { ROUTES, JOB_TYPE } from 'constants/Constants';

// Interfaces
import { Application } from 'interfaces/Job.types';

// Services
import {
  fetchApplicantsService,
  updateApplicantsStatusService,
} from 'services/JobService';

// Styles
import styles from './CareersPendingApplicants.module.scss';

interface Props {
  type: string;
}

const CareersPendingApplicants = ({ type }: Props) => {
  // fetch Applicants state
  const [applicants, setApplicants] = useState<Array<any> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  // fetch all the applicants list
  const getPendingApplicants = useCallback(async () => {
    setApplicants(null);
    setLoading(true);
    setError('');
    const { applicants, error } = await fetchApplicantsService({ type });
    if (applicants) {
      if (applicants.length) {
        const copy = applicants.map((job) => {
          if (job.applications && job.applications.length) {
            return {
              ...job,
              applications: orderBy(
                job.applications,
                ['created_at', 'top_candidate'],
                ['desc', 'desc']
              ),
            };
          }
          return job;
        });
        setApplicants(copy);
      }
      setApplicants(applicants);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [type]);

  const callAnalytics = (jobId: string, applicantId: string) => {
    const jobApplication = applicants.filter((app) => app.job_id === jobId)[0];

    const applicant = jobApplication.applications.filter(
      (applicant) => applicant.application_id === applicantId
    )[0];

    window.analytics.track('Candidate hired, Completed Job', {
      jobTitle: jobApplication.title,
      companyName: jobApplication.company_name,
      hiredCandidate: applicant.email,
    });
  };

  const toggleApplicantNotInterested = async (
    applicationId: string,
    isInterested: boolean
  ) => {
    const { isUpdated } = await updateApplicantsStatusService(applicationId, {
      is_interested: isInterested,
    });

    if (isUpdated) {
      getPendingApplicants();
    }
  };

  const toggleApplicantAsTopCandidate = async (
    applicationId: string,
    isTopCandidate: boolean
  ) => {
    const { isUpdated } = await updateApplicantsStatusService(applicationId, {
      top_candidate: isTopCandidate,
    });

    if (isUpdated) {
      getPendingApplicants();
    }
  };

  const toggleApplicantHired = async (
    jobId: string,
    applicationId: string,
    isHired: boolean
  ) => {
    const { isUpdated } = await updateApplicantsStatusService(applicationId, {
      is_hired: isHired,
    });

    if (isUpdated) {
      callAnalytics(jobId, applicationId);
      getPendingApplicants();
    }
  };

  useEffect(() => {
    getPendingApplicants();
  }, [getPendingApplicants]);

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>Pending Applicants</div>
              </Col>
            </Row>
            <div className={styles.applicantsAndApplications}>
              {loading ? <Loader /> : null}
              {!applicants && error ? (
                <Alert variant="danger">{error}</Alert>
              ) : null}
              {applicants && applicants.length === 0 ? (
                <Alert variant="primary">No applicants found</Alert>
              ) : null}
              {applicants &&
                applicants.length > 0 &&
                applicants.map((applicant) => (
                  <div key={applicant.job_id}>
                    <Row>
                      <Col>
                        <div className={styles.appPost}>
                          <Link
                            to={
                              type === JOB_TYPE.career
                                ? `${ROUTES.CAREER}/${applicant.job_id}`
                                : `${ROUTES.OPPORTUNITY}/${applicant.job_id}`
                            }
                          >
                            <div className={styles.appPostName}>
                              {applicant.title}
                            </div>
                          </Link>

                          <div className={styles.appPostDuration}>
                            {`${applicant.deadline} left `} |{' '}
                            {applicant.applicants} applicants
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {applicant.applications &&
                          applicant.applications.length > 0 &&
                          applicant.applications.map(
                            (application: Application) => (
                              <Applicants
                                {...application}
                                key={application.application_id}
                                jobId={applicant.job_id}
                                note={application.note}
                                applicationId={application.application_id}
                                userId={application.user_id}
                                image={
                                  application.photo ||
                                  'https://placehold.co/70x70'
                                }
                                resume={
                                  application.resume || application.user_resume
                                }
                                title={`${application.first_name} ${application.last_name}`}
                                location={`${application.city}, ${application.state}`}
                                phone={application.phone}
                                email={application.email}
                                slug={application.slug}
                                ideology={application.ideology}
                                party_affiliation={
                                  application.party_affiliation
                                }
                                isTopCandidate={application.top_candidate}
                                markNotInterested={toggleApplicantNotInterested}
                                markAsTopCandidate={
                                  toggleApplicantAsTopCandidate
                                }
                                markAsHired={toggleApplicantHired}
                              />
                            )
                          )}
                        {!applicants && error ? (
                          <Alert variant="danger">{error}</Alert>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default CareersPendingApplicants;
