import React from 'react';
import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

//IMAGES
import Skills from '../../assets/imgs/CaseStudy/skills.svg';
import Opportunity from '../../assets/imgs/CaseStudy/opportunity.svg';
import Profiles from '../../assets/imgs/CaseStudy/profiles.svg';
import Employment from '../../assets/imgs/CaseStudy/employment.svg';
import Visibility from '../../assets/imgs/CaseStudy/visibility.svg';
import Beto from '../../assets/imgs/CaseStudy/beto.png';
import CarrerMap from '../../assets/imgs/CaseStudy/carrerMap.png';
import User from '../../assets/imgs/CaseStudy/testi-user.png';

// SCSS
import styles from './CaseStudy.module.scss';

const CaseStudy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.className = 'caseStudy-page';
    return () => {
      document.body.className = '';
    };
  }, []);
  return (
    <div className={styles.caseStudyWrapper}>
      <Container fluid className={styles.caseStudyBannerWrapper}>
        <Row>
          <Col>
            <Container className="p-0">
              <Row>
                <Col>
                  <div className={styles.caseStudyBanner}>
                    <div className={styles.compLogo}>
                      <a
                        href="https://betoorourke.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Beto} alt="Opportunity" />
                      </a>
                    </div>
                    <div className={styles.compInfo}>
                      <div className={styles.caseLable}>Case Study</div>
                      <div className={styles.compName}>Beto O’ Rourke</div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <div className={styles.caseStudyContent}>
        <Container fluid>
          <Row>
            <Col>
              <Container className="p-0">
                <Row>
                  <Col lg={6}>
                    <div className={styles.gridHeading}>
                      Democratizing The Political Hiring Process
                    </div>
                    <div className={styles.gridDescription}>
                      <p>
                        Beto O’Rourke’s 2022 Texas Governor Campaign is a race
                        to turn Texas Blue. With unprecedented support from
                        Democrats across the state, the Progressive candidate’s
                        team sought a tech solution to make the application
                        process easy and efficient for all applicants.
                      </p>
                      <p>
                        Beto’s team enlisted Capitol Community, a revolutionary
                        new tech platform, as the exclusive hiring portal for
                        initial applicants.
                      </p>
                      <p>
                        Capitol Community is a bipartisan marketplace allowing
                        campaigns to post jobs, manage applicants, and hire
                        staff/volunteers, all for free.
                      </p>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className={styles.gridHeading}>Free Features</div>
                    <div className={styles.featureList}>
                      <ul>
                        <li>
                          <span className={styles.imgSec}>
                            <img src={Skills} alt="Skills" />
                          </span>
                          <span>
                            Matching candidates to jobs based on qualifying
                            skills, education, experience, and more.
                          </span>
                        </li>
                        <li>
                          <span className={styles.imgSec}>
                            <img src={Employment} alt="Employment" />
                          </span>
                          <span>
                            Certificates verifying experiences around previous
                            employment, engagements, and more.{' '}
                          </span>
                        </li>
                        <li>
                          <span className={styles.imgSec}>
                            <img src={Visibility} alt="Visibility" />
                          </span>
                          <span>
                            Campaigns receive free promotional visibility
                            through targeted email, social, and ad channels.
                          </span>
                        </li>
                        <li>
                          <span className={styles.imgSec}>
                            <img src={Profiles} alt="Profiles" />
                          </span>
                          <span>
                            Campaigns can easily view applicant profiles and
                            sort based on preference, priority, and more.
                          </span>
                        </li>
                        <li>
                          <span className={styles.imgSec}>
                            <img src={Opportunity} alt="Opportunity" />
                          </span>
                          <span>
                            Campaigns can post unlimited opportunities
                            regardless of size, structure, or duration.
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.caseStudyTestimonal}>
        <Container fluid>
          <Row>
            <Col>
              <Container className="p-0">
                <Row>
                  <Col md={12}>
                    <div className={styles.testiDescription}>
                      “Political hiring can be complicated for large campaigns,
                      but Capitol Community made it easy. Having all applicants
                      in one system allowed us to fill positions quickly and get
                      staff on the ground across Texas. I would highly recommend
                      this tool to any campaign needing to hire staff or
                      volunteers.“
                    </div>
                    <div className={styles.testiInfo}>
                      <div className={styles.testUser}>
                        <img src={User} alt="Opportunity" />
                      </div>
                      <div className={styles.testiUserDetails}>
                        <div className={styles.testiName}>Nick Rathod</div>
                        <div className={styles.testiAbout}>
                          Campaign Manager: Beto For Texas
                        </div>
                      </div>
                      <div className={styles.testiLogo}>
                        <a
                          href="https://betoorourke.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={Beto} alt="Opportunity" />
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.caseStudyResults}>
        <Container fluid>
          <Row>
            <Col>
              <Container className="p-0">
                <Row>
                  <Col md={12}>
                    <div className={styles.resultsHeading}>Results</div>
                  </Col>
                </Row>
                <Row className="mb-5 pb-5">
                  <Col md={3}>
                    <div className={styles.resultSubHeading}>$1,000,000+</div>
                    <div className={styles.resultsInfo}>
                      Job Salary Facilitation
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className={styles.resultSubHeading}>1,000+</div>
                    <div className={styles.resultsInfo}>
                      Applications Received
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className={styles.resultSubHeading}>20</div>
                    <div className={styles.resultsInfo}>Senior Staff Hires</div>
                  </Col>
                  <Col md={3}>
                    <div className={styles.resultSubHeading}>9</div>
                    <div className={styles.resultsInfo}>Departments Hiring</div>
                  </Col>
                </Row>
                <Row>
                  <Col md={5}>
                    <div className={styles.resultsInfoHeading}>
                      Capitol Community is here to help you win your campaign.
                    </div>
                  </Col>
                  <Col md={7}>
                    <div className={styles.resultsInfoDesc}>
                      <p>
                        Capitol Community is free for campaigns of any size,
                        affiliation, or ideology. The platform’s matchmaking
                        algorithm help connect applicants to opportunities based
                        on a variety of factors, including experience,
                        interests, location, and more unique data.
                      </p>
                      <p>
                        Capitol Community will soon launch a variety of
                        additional tools, including an online academy, automated
                        assessments, credentialing services, project management,
                        expert consulting, and more.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.carrerMapWrapper}>
        <Container fluid>
          <Row>
            <Col>
              <Container className="p-0">
                <Row>
                  <Col md={12}>
                    <div className={styles.mapInnerWrapper}>
                      <img src={CarrerMap} alt="Opportunity" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className={styles.mapInnerDetails}>
                      <p>
                        Capitol Community is a part of the Career Map family of
                        products. Career Map has helped thousands worldwide gain
                        access to new skills, educational content, professional
                        opportunities, expert networks, and more.
                      </p>
                      <p className="m-0">
                        <a
                          href="http://mycareermaps.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More
                        </a>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default CaseStudy;
