interface Dictionary<T> {
  [key: string]: T;
}

const FIELD_LABELS: Dictionary<string | number> = {
  title: 'Title',
  category: 'Category',
  pay: 'Pay',
  rate: 'Rate',
  expectedNeed: 'Expected need',
  location: 'Location',
  companyName: 'Company name',
  companyLogo: 'Company logo',
  deadline: 'Deadline',
  zip: 'Zip',
  state: 'State',
  city: 'City',
  opportunityDescription: 'Opportunity description',
  hiringDescription: 'Hiring description',
  applyThrough: 'Apply through',
  externalUrl: 'External URL',
  fee: 'Fee',
  referenceLinks: 'Reference link',
  minPay: 'Rate low end',
  maxPay: 'Rate high end',
  education: 'Education',
  expertise: 'Expertise',
  experience: 'Experience',
  ideology: 'Ideology',
  partyAffiliation: 'Party Affiliation',
  credentialTitle: 'Credential Name Title',
  credential: 'Credential',
};

export default FIELD_LABELS;
