import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { Row, Col, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';

// Components
import Input from 'components/Form/Input/Input';
import Select from 'components/Form/Select/Select';
import Checkbox from 'components/Form/Checkbox/Checkbox';
import TextArea from 'components/Form/TextArea/TextArea';
import Button from 'components/Button/Button';
import FileUpload from 'components/FileUpload/FileUpload';

import { Multiselect } from 'multiselect-react-dropdown';

import PaymentForm from 'components/PaymentForm/PaymentForm';

// Interfaces
import {
  OpportunityForm,
  OpportunityFormErrors,
} from 'interfaces/OpportunityForm.types';
import { Job } from 'interfaces/Job.types';
import { Category } from 'interfaces/Category.types';

// Services
import { createJobService, updateJobService } from 'services/JobService';
import {
  getUploadURLService,
  verifyCouponService,
} from 'services/CommonService';

// Utilities
import { camelToSnake, getFileName } from 'utils/UtilityFunctions';

// Constants
import STATES from 'constants/States';
import OPPORTUNITY_RULES from '../../constants/OpportunityRules';
import {
  DEFAULT_OPPORTUNITY_ERRORS,
  DEFAULT_OPPORTUNITY_VALUES,
} from '../../constants/DefaultValues';
import FIELD_LABELS from '../../constants/FieldLabels';
import {
  APP_CONSTANTS,
  FILE_TYPE,
  ROUTES,
  EDUCATION_OPTIONS,
  EXPERTISE_OPTIONS_SERVICE,
  PARTY_IMAGES,
} from 'constants/Constants';

// SCSS
import styles from './Opportunity.module.scss';

interface CouponResponse {
  id: string;
  amount: number;
}

interface PostProps {
  id: string;
  job: Job;
  categories: Array<Category>;
  relist: boolean;
}

const Post: React.FC<PostProps> = ({
  id: postId,
  job,
  categories,
  relist,
}: PostProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [jobId, setJobId] = useState<string>('');
  const [apiError, setApiError] = useState<string>('');
  const [logo, setLogo] = useState<File | null>(null);
  const [credential, setCredential] = useState<File | null>(null);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [code, setCode] = useState<string>('');
  const [coupon, setCoupon] = useState<CouponResponse | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [couponError, setCouponError] = useState<string>('');

  const [opportunity, setOpportunity] = useState<OpportunityForm>(
    DEFAULT_OPPORTUNITY_VALUES
  );

  const [opportunityFormErrors, setOpportunityFormErrors] = useState<
    OpportunityFormErrors
  >(DEFAULT_OPPORTUNITY_ERRORS);

  const [currentCredential, setCurrentCredential] = useState<string>(
    job?.credential
      ? job.credential.substr(job.credential.lastIndexOf('/') + 1)
      : ''
  );

  const {
    errorConstants: { SOMETHING_WENT_WRONG: serverError },
    featuredAmount,
  } = APP_CONSTANTS;

  const fileSpecs = ['5mb max', '500px wide', '500px tall'];

  useEffect(() => {
    if (job) {
      const obj: any = {};
      Object.keys(DEFAULT_OPPORTUNITY_VALUES).map((key: string) => {
        obj[key] = job[camelToSnake(key)];
        if (obj[key] === null) {
          obj[key] = '';
        }
        return key;
      });
      if (!obj.referenceLinks || !obj.referenceLinks.length) {
        obj.referenceLinks = ['', '', ''];
      }
      setOpportunity(obj);
      if (relist) {
        let amount = 0;
        if (obj.isFeatured) {
          amount += featuredAmount;
        }
        setAmount(amount);
      }
    }
  }, [job, featuredAmount, relist]);

  // const handleReferenceLink = (event: any, index: number) => {
  //   if (apiSuccess) {
  //     setApiSuccess(false);
  //     setJobId('');
  //   }
  //   if (couponError) {
  //     setCouponError('');
  //   }
  //   const { value, name } = event.target;
  //   const links = [...opportunity.referenceLinks];
  //   links[index] = value;
  //   setOpportunity({
  //     ...opportunity,
  //     referenceLinks: links,
  //   });

  //   if (value && !OPPORTUNITY_RULES.referenceLinks.pattern.test(value)) {
  //     const linkErrors = [...opportunityFormErrors.referenceLinks];
  //     linkErrors[index] = `${FIELD_LABELS[name]} is invalid`;
  //     setOpportunityFormErrors({
  //       ...opportunityFormErrors,
  //       referenceLinks: linkErrors,
  //     });
  //   }

  //   if (!value || OPPORTUNITY_RULES.referenceLinks.pattern.test(value)) {
  //     const linkErrors = [...opportunityFormErrors.referenceLinks];
  //     linkErrors[index] = '';
  //     setOpportunityFormErrors({
  //       ...opportunityFormErrors,
  //       referenceLinks: linkErrors,
  //     });
  //   }
  // };

  const handleCredentialChange = (credential: File | null) => {
    setCurrentCredential('');
    setCredential(credential);
  };

  const validateField = (target: any) => {
    const { name, value, type, checked } = target;
    if (type === 'checkbox') {
      if (
        !checked &&
        OPPORTUNITY_RULES[name].required &&
        (name !== 'ideology' ||
          !opportunity[name] ||
          opportunity[name].indexOf(',') === -1)
      ) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: '',
        });
      }
    } else {
      if (name === 'pay' && value && value < 1) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} can either be blank (non-paying) or greater than 0`,
        });
      } else if (name === 'fee' && value && value < 1) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} can either be blank (no-fee) or greater than 0`,
        });
      } else if (
        name === 'externalUrl' &&
        !value &&
        opportunity['applyThrough'] === 'external'
      ) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else if (OPPORTUNITY_RULES[name].required && !value) {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: `${FIELD_LABELS[name]} is required`,
        });
      } else if (value) {
        if (
          OPPORTUNITY_RULES[name].minLength &&
          value.toString().length < OPPORTUNITY_RULES[name].minLength
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} should contain at least ${OPPORTUNITY_RULES[name].minLength} characters`,
          });
        } else if (
          OPPORTUNITY_RULES[name].maxLength &&
          value.toString().length > OPPORTUNITY_RULES[name].maxLength
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} can not be longer than ${OPPORTUNITY_RULES[name].maxLength} characters`,
          });
        } else if (
          OPPORTUNITY_RULES[name].min &&
          value < OPPORTUNITY_RULES[name].min
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} should be greater than ${OPPORTUNITY_RULES[name].min}`,
          });
        } else if (
          OPPORTUNITY_RULES[name].max &&
          value < OPPORTUNITY_RULES[name].max
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} should be lesser than ${OPPORTUNITY_RULES[name].max}`,
          });
        } else if (
          OPPORTUNITY_RULES[name].pattern &&
          !OPPORTUNITY_RULES[name].pattern.test(value)
        ) {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: `${FIELD_LABELS[name]} is invalid`,
          });
        } else {
          setOpportunityFormErrors({
            ...opportunityFormErrors,
            [name]: '',
          });
        }
      } else {
        setOpportunityFormErrors({
          ...opportunityFormErrors,
          [name]: '',
        });
      }
    }
    return;
  };

  const handleCodeChange = (event: any) => {
    setCouponError('');
    setCode(event.target.value);
  };

  const handleFeatured = (val: boolean) => {
    if (apiSuccess) {
      setApiSuccess(false);
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    if (couponError) {
      setCouponError('');
    }
    setOpportunity({
      ...opportunity,
      isFeatured: val,
    });

    setOpportunityFormErrors({
      ...opportunityFormErrors,
      isFeatured: '',
    });

    let amount = val ? featuredAmount : 0;

    setAmount(amount);
    setCoupon(null);
    setCode('');
  };

  const handleCategorySelect = (categoryList: Array<Category | any>) => {
    setOpportunity({
      ...opportunity,
      category: categoryList,
    });

    if (!categoryList.length) {
      setOpportunityFormErrors({
        ...opportunityFormErrors,
        category: 'Category is required',
      });
    } else {
      setOpportunityFormErrors({
        ...opportunityFormErrors,
        category: '',
      });
    }
  };

  const handleChange = (event: any) => {
    if (apiSuccess) {
      setApiSuccess(false);
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    const { name, value, type, checked } = event.target;
    if (type !== 'checkbox') {
      setOpportunity({
        ...opportunity,
        [name]:
          (name === 'pay' || name === 'fee') && value
            ? parseFloat(value)
            : value,
      });
      if (name === 'fee') {
        let amount =
          opportunity.isFeatured && (!postId || relist) ? featuredAmount : 0;

        setAmount(amount);
        setCoupon(null);
        setCode('');
      }
    } else {
      if (name === 'applyThrough' && value === 'site') {
        setOpportunity({
          ...opportunity,
          [name]: checked ? value : '',
          externalUrl: '',
        });
      } else if (name === 'ideology') {
        if (checked) {
          setOpportunity({
            ...opportunity,
            ideology: opportunity.ideology
              ? `${opportunity.ideology},${value}`
              : value,
          });
        } else {
          const valuesArr = opportunity.ideology
            .split(',')
            .filter((val) => val !== value);
          setOpportunity({
            ...opportunity,
            ideology: valuesArr.join(','),
          });
        }
      } else {
        setOpportunity({
          ...opportunity,
          [name]: checked ? value : '',
        });
      }
    }
    validateField(event.target);
  };

  const uploadLogo = async () => {
    let uploadImageResponse: any = null;
    const fileInfo = {
      name: getFileName(logo?.name),
      type: logo?.type,
    };
    const { signedRequest, url, error } = await getUploadURLService(fileInfo);

    if (signedRequest && url) {
      uploadImageResponse = await fetch(signedRequest, {
        method: 'PUT',
        body: logo,
      });

      if (uploadImageResponse) {
        return url;
      }
    } else if (error) {
      setApiError(`An error occurred while uploading logo: ${error}`);
      setIsLoading(false);
      return null;
    }
  };

  const uploadCredential = async () => {
    let uploadImageResponse: any = null;
    const fileInfo = {
      name: getFileName(credential?.name),
      type: credential?.type,
    };
    const { signedRequest, url, error } = await getUploadURLService(fileInfo);

    if (signedRequest && url) {
      uploadImageResponse = await fetch(signedRequest, {
        method: 'PUT',
        body: credential,
      });

      if (uploadImageResponse) {
        return url;
      }
    } else if (error) {
      setApiError(`An error occurred while uploading credential: ${error}`);
      setIsLoading(false);
      return null;
    }
  };

  const verifyCouponCode = async () => {
    let amount = opportunity.isFeatured ? featuredAmount : 0;
    if (coupon) {
      setCode('');
      setCoupon(null);
      setAmount(amount);
      return;
    }
    if (!code) {
      return;
    }
    setCouponError('');
    setIsLoading(true);
    const { id, type, error } = await verifyCouponService(code);

    if (id) {
      setCoupon({
        id,
        amount: type === 'full' ? amount : amount / 2,
      });

      setAmount(type === 'full' ? 0 : amount / 2);

      setIsLoading(false);
    } else if (error) {
      setCouponError(error);
      setIsLoading(false);
    }
  };

  const validateFields = () => {
    if (apiSuccess) {
      setApiSuccess(false);
      setJobId('');
    }
    if (apiError) {
      setApiError('');
    }
    const errors = Object.create({});
    Object.keys(opportunityFormErrors).map((name) => {
      if (name === 'pay' && opportunity[name] && opportunity[name] < 1) {
        errors[
          name
        ] = `${FIELD_LABELS[name]} can either be blank (non-paying) or greater than 0`;
      } else if (name === 'fee' && opportunity[name] && opportunity[name] < 1) {
        errors[
          name
        ] = `${FIELD_LABELS[name]} can either be blank (no-fee) or greater than 0`;
      } else if (
        name === 'externalUrl' &&
        !opportunity[name] &&
        opportunity['applyThrough'] === 'external'
      ) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (name === 'category' && !opportunity[name].length) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (
        name === 'isFeatured' &&
        OPPORTUNITY_RULES[name].required &&
        opportunity[name] === null
      ) {
        errors[name] = 'Please choose if you would like your post featured';
      } else if (
        OPPORTUNITY_RULES[name].required &&
        !opportunity[name] &&
        name !== 'isFeatured'
      ) {
        errors[name] = `${FIELD_LABELS[name]} is required`;
      } else if (opportunity[name] && name !== 'isFeatured') {
        if (
          OPPORTUNITY_RULES[name].minLength &&
          opportunity[name].toString().length <
            OPPORTUNITY_RULES[name].minLength
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should contain at least ${OPPORTUNITY_RULES[name].minLength} characters`;
        } else if (
          OPPORTUNITY_RULES[name].maxLength &&
          opportunity[name].toString().length >
            OPPORTUNITY_RULES[name].maxLength
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} can not be longer than ${OPPORTUNITY_RULES[name].maxLength} characters`;
        } else if (
          OPPORTUNITY_RULES[name].min &&
          opportunity[name] < OPPORTUNITY_RULES[name].min
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should be greater than ${OPPORTUNITY_RULES[name].min}`;
        } else if (
          OPPORTUNITY_RULES[name].max &&
          opportunity[name] < OPPORTUNITY_RULES[name].max
        ) {
          errors[
            name
          ] = `${FIELD_LABELS[name]} should be lesser than ${OPPORTUNITY_RULES[name].max}`;
        } else if (
          name === 'referenceLinks' &&
          OPPORTUNITY_RULES[name].pattern
        ) {
          errors[name] = [];
          for (let i = 0; i < opportunity[name].length; i++) {
            if (
              opportunity[name][i] &&
              !OPPORTUNITY_RULES[name].pattern.test(opportunity[name][i])
            ) {
              errors[name][i] = `${FIELD_LABELS[name]} is invalid`;
            }
          }
          if (!errors[name].length) {
            delete errors[name];
          }
        } else if (
          OPPORTUNITY_RULES[name].pattern &&
          !OPPORTUNITY_RULES[name].pattern.test(opportunity[name])
        ) {
          errors[name] = `${FIELD_LABELS[name]} is invalid`;
        }
      }
      return name;
    });
    if (Object.keys(errors).length) {
      setOpportunityFormErrors({
        ...opportunityFormErrors,
        ...errors,
      });
      setApiError('There are some errors above, please fix those to proceed.');
      return false;
    }
    return true;
  };

  // Todo Validate file specs

  const callAnalytics = () => {
    window.analytics.track('Posted Opportunity', {
      careerTitle: opportunity.title,
      category: opportunity.category,
      companyName: opportunity.companyName,
      cost: opportunity.fee,
      pay: opportunity.pay,
      rate: opportunity.rate,
      duration: opportunity.expectedNeed,
      workLocation: opportunity.location,
      company: opportunity.companyName,
      city: opportunity.city,
      state: opportunity.state,
      jobDescription: opportunity.opportunityDescription,
      companyDescription: opportunity.hiringDescription,
      featureJob: opportunity.isFeatured,
    });
  };

  const createJob = async (opportunityFormData: any) => {
    const response = await createJobService(opportunityFormData);

    if (!response.ok) {
      const error = await response.clone().text();
      setApiError(error || serverError);
    } else {
      const { id } = await response.json();
      callAnalytics();
      setJobId(id);
      setApiSuccess(true);
      setOpportunity(DEFAULT_OPPORTUNITY_VALUES);
    }

    setIsLoading(false);
  };

  const updateJob = async (opportunityFormData: any) => {
    const { id, error } = await updateJobService(postId, opportunityFormData);

    if (error) {
      setApiError(error || serverError);
    } else {
      setJobId(id);
      setApiSuccess(true);
    }

    setIsLoading(false);
  };

  /**
   * Create a opportunity
   */
  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    if (amount && !selectedCard) {
      setApiError('Please select/add a card to proceed with the payment');
      return;
    }

    setApiError('');
    setApiSuccess(false);
    setJobId('');
    setIsLoading(true);

    let uploadResponse = '';
    let credResponse = '';

    if (logo) {
      uploadResponse = await uploadLogo();
      if (!uploadResponse) {
        return;
      }
    }

    if (credential) {
      credResponse = await uploadCredential();
      if (!credResponse) {
        return;
      }
    }

    const opportunityFormData: any = {};
    Object.keys(opportunity).map((key: string) => {
      opportunityFormData[camelToSnake(key)] = opportunity[key];
      return key;
    });
    opportunityFormData['company_logo'] =
      uploadResponse || (job && job.company_logo ? job.company_logo : '');
    opportunityFormData['credential'] =
      credResponse || (job && job.credential ? job.credential : '');
    if (!opportunityFormData.pay) {
      opportunityFormData.pay = null;
    }
    if (!opportunityFormData.fee) {
      opportunityFormData.fee = null;
    }
    opportunityFormData.reference_links = opportunity['referenceLinks'].filter(
      (val) => {
        return !!val;
      }
    );
    opportunityFormData.category = opportunity.category
      .map((item) => item.name)
      .join(',');
    if ((!postId || relist) && opportunityFormData.is_featured) {
      if (amount) {
        opportunityFormData.cardId = selectedCard;
      }
      if (coupon) {
        opportunityFormData.coupon_id = coupon.id;
      }
    }
    opportunityFormData.type = 'opportunity';

    if (!postId || relist) {
      createJob(opportunityFormData);
    } else {
      delete opportunityFormData['is_featured'];
      updateJob(opportunityFormData);
    }
  };

  const renderTooltipCost = (props: any) => (
    <Tooltip id="button-tooltipCost" {...props}>
      What will you charge someone for your service?
    </Tooltip>
  );

  return (
    <Row className={styles.opportunityType}>
      <Col sm={12}>
        <div className={`${styles.requiredFieldsNote} text-right`}>
          <span>* </span>= Required Fields
        </div>
      </Col>
      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Service Post Title <span>(max 100 char)</span>
          <span className={styles.requiredField}> *</span>
        </div>
        <Input
          type="text"
          onChange={handleChange}
          value={opportunity.title}
          name="title"
        />
        {opportunityFormErrors.title && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>{opportunityFormErrors.title}</div>
          </Alert>
        )}
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Category
          <span className={styles.requiredField}> *</span>
          <span>
            (If you don&apos;t see your category listed,{' '}
            <a
              href="https://capitolcommunity.activehosted.com/f/5"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </a>{' '}
            to submit it.)
          </span>
        </div>
        <Multiselect
          options={categories || []}
          isObject={true}
          displayValue="name"
          selectedValues={opportunity.category}
          showCheckbox
          singleSelect={true}
          onSelect={handleCategorySelect}
          onRemove={handleCategorySelect}
        />
        {opportunityFormErrors.category && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>{opportunityFormErrors.category}</div>
          </Alert>
        )}
      </Col>

      <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Fee
          <OverlayTrigger
            placement="top"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltipCost}
          >
            <div className={styles.infoHover}>i</div>
          </OverlayTrigger>
        </div>
        <Input
          type="number"
          placeholder="$"
          onChange={handleChange}
          value={opportunity.fee}
          name="fee"
        />
        {opportunityFormErrors.fee && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>{opportunityFormErrors.fee}</div>
          </Alert>
        )}
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Fee Structure
          <span className={styles.requiredField}> *</span>
        </div>
        <div className={styles.checkRow}>
          <Checkbox
            id="free1"
            name="rate"
            isChecked={opportunity.rate === 'free'}
            onChange={handleChange}
            label="Non-Paying"
            value="free"
          />
          <Checkbox
            id="perHour1"
            name="rate"
            isChecked={opportunity.rate === 'hour'}
            onChange={handleChange}
            label="Per Hour"
            value="hour"
          />
          <Checkbox
            id="perMonth"
            name="rate"
            isChecked={opportunity.rate === 'month'}
            onChange={handleChange}
            label="Monthly"
            value="month"
          />
          <Checkbox
            id="flatFee1"
            name="rate"
            isChecked={opportunity.rate === 'flat'}
            onChange={handleChange}
            label="Flat Fee"
            value="flat"
          />
          <Checkbox
            id="salaryOpp"
            name="rate"
            isChecked={opportunity.rate === 'salary'}
            onChange={handleChange}
            label="Salary"
            value="salary"
          />
          <Checkbox
            id="tbd"
            name="rate"
            isChecked={opportunity.rate === 'TBD'}
            onChange={handleChange}
            label="TBD"
            value="TBD"
          />
        </div>
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Position
          <span className={styles.requiredField}> *</span>
        </div>
        <div className={styles.checkRow}>
          <Checkbox
            id="fullTime1"
            name="expectedNeed"
            isChecked={opportunity.expectedNeed === 'full time'}
            onChange={handleChange}
            label="Full Time"
            value="full time"
          />
          <Checkbox
            id="partTime1"
            name="expectedNeed"
            isChecked={opportunity.expectedNeed === 'part time'}
            onChange={handleChange}
            label="Part Time"
            value="part time"
          />
          <Checkbox
            id="oneTime1"
            name="expectedNeed"
            isChecked={opportunity.expectedNeed === 'one time'}
            onChange={handleChange}
            label="One Time"
            value="one time"
          />
          <Checkbox
            id="freelanceOpp"
            name="expectedNeed"
            isChecked={opportunity.expectedNeed === 'freelance'}
            onChange={handleChange}
            label="Freelance"
            value="freelance"
          />
        </div>
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Service Location
          <span className={styles.requiredField}> *</span>
        </div>
        <div className={styles.checkRow}>
          <Checkbox
            id="remote1"
            name="location"
            isChecked={opportunity.location === 'remote'}
            onChange={handleChange}
            label="Remote"
            value="remote"
          />
          <Checkbox
            id="onSite1"
            name="location"
            isChecked={opportunity.location === 'on site'}
            onChange={handleChange}
            label="OnSite (Office Location)"
            value="on site"
          />
          <Checkbox
            id="onSite2"
            name="location"
            isChecked={opportunity.location === 'on multiple sites'}
            onChange={handleChange}
            label="OnSite (Multiple Locations; See Description)"
            value="on multiple sites"
          />
        </div>
        {opportunityFormErrors.location && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>{opportunityFormErrors.location}</div>
          </Alert>
        )}
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Education{' '}
          <span style={{ fontWeight: 'normal' }}>
            (Please select your minimum level required, including “No
            Preference” if applicable)
          </span>
          <span className={styles.requiredField}> *</span>
        </div>
        <Select
          id="education1"
          selectItems={EDUCATION_OPTIONS}
          onChange={handleChange}
          name="education"
          value={opportunity.education}
        />
        {opportunityFormErrors.education && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.education}
            </div>
          </Alert>
        )}
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Expertise{' '}
          <span style={{ fontWeight: 'normal' }}>
            (What is your preferred level of experience)
          </span>
          <span className={styles.requiredField}> *</span>
        </div>
        <Select
          id="expertise1"
          selectItems={EXPERTISE_OPTIONS_SERVICE}
          onChange={handleChange}
          name="expertise"
          value={opportunity.expertise}
        />
        {opportunityFormErrors.expertise && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.expertise}
            </div>
          </Alert>
        )}
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>Credentials:</div>
        <FileUpload
          fileType={FILE_TYPE.DOCUMENT}
          fileSpecs={[]}
          onFileChange={handleCredentialChange}
          currentUploadedFileName={currentCredential}
          reset={apiSuccess && !postId}
        />
        {opportunityFormErrors.credential && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.credential}
            </div>
          </Alert>
        )}
      </Col>

      <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Service Company/Individual Name
          <span className={styles.requiredField}> *</span>
        </div>
        <Input
          type="text"
          onChange={handleChange}
          value={opportunity.companyName}
          name="companyName"
        />
        {opportunityFormErrors.companyName && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.companyName}
            </div>
          </Alert>
        )}
      </Col>

      <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.inputLabel}>Company Logo/Image</div>
        <FileUpload
          fileType={FILE_TYPE.IMAGE}
          fileSpecs={fileSpecs}
          onFileChange={setLogo}
          image={job?.company_logo}
          reset={apiSuccess && !postId}
          selectedOption={opportunity?.defaultIcon}
          options={PARTY_IMAGES}
          onOptionChange={handleChange}
          name="defaultIcon"
        />
        {opportunityFormErrors.companyLogo && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.companyLogo}
            </div>
          </Alert>
        )}
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <div className={styles.inputLabel}>
              City
              <span className={styles.requiredField}> *</span>
            </div>
            <Input
              type="text"
              onChange={handleChange}
              value={opportunity.city}
              name="city"
            />
            {opportunityFormErrors.city && (
              <Alert variant={'danger'} className={styles.apiError}>
                <div className={styles.error}>{opportunityFormErrors.city}</div>
              </Alert>
            )}
          </Col>
          <Col xl={3} lg={3} md={12} sm={12}>
            <div className={styles.inputLabel}>
              State
              <span className={styles.requiredField}> *</span>
            </div>
            <Select
              id="state1"
              selectItems={STATES}
              onChange={handleChange}
              name="state"
              value={opportunity.state}
            />
            {opportunityFormErrors.state && (
              <Alert variant={'danger'} className={styles.apiError}>
                <div className={styles.error}>
                  {opportunityFormErrors.state}
                </div>
              </Alert>
            )}
          </Col>
          <Col xl={3} lg={3} md={12} sm={12}>
            <div className={styles.inputLabel}>
              Zip
              <span className={styles.requiredField}> *</span>
            </div>
            <Input
              type="text"
              onChange={handleChange}
              value={opportunity.zip}
              name="zip"
            />
            {opportunityFormErrors.zip && (
              <Alert variant={'danger'} className={styles.apiError}>
                <div className={styles.error}>{opportunityFormErrors.zip}</div>
              </Alert>
            )}
          </Col>
        </Row>
      </Col>

      <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Service Description
          <span className={styles.requiredField}> *</span>
        </div>
        <TextArea
          onChange={handleChange}
          value={opportunity.opportunityDescription}
          name="opportunityDescription"
        />
        {opportunityFormErrors.opportunityDescription && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.opportunityDescription}
            </div>
          </Alert>
        )}
      </Col>

      <Col className="mb-3" xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Service Company/Individual Description
          <span style={{ fontWeight: 'normal' }}>
            (Can be TBA if necessary)
          </span>
          <span className={styles.requiredField}> *</span>
        </div>
        <TextArea
          onChange={handleChange}
          value={opportunity.hiringDescription}
          name="hiringDescription"
        />
        {opportunityFormErrors.hiringDescription && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.hiringDescription}
            </div>
          </Alert>
        )}
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Service Party Affiliation
          <span className={styles.requiredField}> *</span>
        </div>
        <div className={styles.checkRow}>
          <Checkbox
            id="republican1"
            name="partyAffiliation"
            isChecked={opportunity.partyAffiliation === 'Republican'}
            onChange={handleChange}
            label="Republican"
            value="Republican"
          />
          <Checkbox
            id="democratic1"
            name="partyAffiliation"
            isChecked={opportunity.partyAffiliation === 'Democratic'}
            onChange={handleChange}
            label="Democratic"
            value="Democratic"
          />
          <Checkbox
            id="Libertarian1"
            name="partyAffiliation"
            isChecked={opportunity.partyAffiliation === 'Libertarian'}
            onChange={handleChange}
            label="Libertarian"
            value="Libertarian"
          />
          <Checkbox
            id="Independent1"
            name="partyAffiliation"
            isChecked={opportunity.partyAffiliation === 'Independent'}
            onChange={handleChange}
            label="Independent"
            value="Independent"
          />
          <Checkbox
            id="PreferNotToAnswer1"
            name="partyAffiliation"
            isChecked={opportunity.partyAffiliation === 'Prefer not to answer'}
            onChange={handleChange}
            label="Prefer not to answer"
            value="Prefer not to answer"
          />
        </div>
        {opportunityFormErrors.partyAffiliation && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.partyAffiliation}
            </div>
          </Alert>
        )}
      </Col>

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.inputLabel}>
          Client Ideology{' '}
          <span style={{ fontWeight: 'normal' }}>(Who We Service)</span>
          <span className={styles.requiredField}> *</span>
        </div>
        <div className={styles.checkRow}>
          <Checkbox
            id="Conservative1"
            name="ideology"
            isChecked={opportunity.ideology?.indexOf('Conservative') > -1}
            onChange={handleChange}
            label="Conservative"
            value="Conservative"
          />
          <Checkbox
            id="Moderate1"
            name="ideology"
            isChecked={opportunity.ideology?.indexOf('Moderate') > -1}
            onChange={handleChange}
            label="Moderate"
            value="Moderate"
          />
          <Checkbox
            id="Progressive1"
            name="ideology"
            isChecked={opportunity.ideology?.indexOf('Progressive') > -1}
            onChange={handleChange}
            label="Progressive"
            value="Progressive"
          />
          <Checkbox
            id="preferNot1"
            name="ideology"
            isChecked={
              opportunity.ideology?.indexOf('Prefer not to answer') > -1
            }
            onChange={handleChange}
            label="Prefer not to answer"
            value="Prefer not to answer"
          />
        </div>
        {opportunityFormErrors.ideology && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>{opportunityFormErrors.ideology}</div>
          </Alert>
        )}
      </Col>

      <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.inputLabel}>
          How To Apply
          <span className={styles.requiredField}> *</span>
        </div>
        <div className={styles.checkRow}>
          <Checkbox
            id="onThisSite1"
            name="applyThrough"
            isChecked={opportunity.applyThrough === 'site'}
            onChange={handleChange}
            label="On This Site"
            value="site"
          />
          <Checkbox
            id="externalURL1"
            name="applyThrough"
            isChecked={opportunity.applyThrough === 'external'}
            onChange={handleChange}
            label="External URL"
            value="external"
          />
          <div className={styles.featureDesc}>
            Users applying Onsite can be messaged, organized, and offer access
            to individual profiles, all for free. External listings simply pass
            the user to an offsite link. Both options are free.
          </div>
        </div>
        {opportunityFormErrors.applyThrough && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.applyThrough}
            </div>
          </Alert>
        )}
      </Col>

      <Col xl={6} lg={6} md={12} sm={12}>
        <div className={styles.inputLabel}>External URL</div>
        <Input
          type="text"
          placeholder="http://"
          onChange={handleChange}
          value={opportunity.externalUrl}
          name="externalUrl"
          disabled={opportunity.applyThrough !== 'external'}
        />
        {opportunityFormErrors.externalUrl && (
          <Alert variant={'danger'} className={styles.apiError}>
            <div className={styles.error}>
              {opportunityFormErrors.externalUrl}
            </div>
          </Alert>
        )}
      </Col>

      <div className={styles.formDivider} />

      {(!postId || relist) && (
        <Col xl={7} lg={7} md={12} sm={12}>
          <div className={styles.inputLabel}>
            Do you wish to be featured?
            <span className={styles.requiredField}> *</span>
          </div>
          <div className={styles.featureDesc}>
            Featured listings gain visibility through top placement for category
            listings, in addition to receiving a dedicated email campaign to our
            applicant community and an account representative. Featured Listings
            remain for 30-days from posting and can be renewed in your dashboard
            with a single click.
          </div>
          <div className={styles.featureList}>
            <ul>
              <li>Account Representative</li>
              <li>Top Listing</li>
              <li>Email Campaign</li>
            </ul>
          </div>
          {opportunityFormErrors.isFeatured && (
            <Alert variant={'danger'} className={styles.apiError}>
              <div className={styles.error}>
                {opportunityFormErrors.isFeatured}
              </div>
            </Alert>
          )}
        </Col>
      )}

      {(!postId || relist) && (
        <Col className={styles.limitedPriceData} xl={5} lg={5} md={12} sm={12}>
          <div className={styles.limitedPricing}>Limited Time Pricing</div>
          <div className={styles.limitedPrice}>
            <div className={styles.prices}>
              <s>$29.99</s> &nbsp; <b>$19.99</b>
            </div>
          </div>
          <div className={styles.limitedButtons}>
            <Button
              onClick={() => handleFeatured(false)}
              type={!opportunity.isFeatured ? 'black my-2 w-100' : 'gray w-100'}
              label={`${
                opportunity.isFeatured === false ? '✔ ' : ''
              }I do not want to upgrade`}
            />
            <br />
            <Button
              onClick={() => handleFeatured(true)}
              type={opportunity.isFeatured ? 'black my-2 w-100' : 'gray w-100'}
              label={`${
                opportunity.isFeatured ? '✔ ' : ''
              }I do want to upgrade`}
            />
          </div>
        </Col>
      )}
      {(!postId || relist) && opportunity.isFeatured ? (
        <>
          <div className={styles.formDivider}></div>
          <Col xl={12} lg={12} md={12} sm={12} className={styles.posRel}>
            <Input
              type="text"
              placeholder="Coupon Code"
              onChange={handleCodeChange}
              value={code}
              name="code"
            />
            <Button
              onClick={verifyCouponCode}
              buttonType="button"
              label={!coupon ? 'Apply' : 'Remove'}
              type={`branding ${styles.applyCouponBtn}`}
              disabled={isLoading || !code}
              isLoading={isLoading}
            />
          </Col>
          {couponError && (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.jobSubmitError}>
                <Alert variant={'danger'} className={styles.apiError}>
                  <div className={styles.error}>{couponError}</div>
                </Alert>
              </div>
            </Col>
          )}
          {amount > 0 ? (
            <>
              <Col xl={12} lg={12} md={12} sm={12} className="mt-3">
                <div className={styles.inputLabel}>
                  {coupon
                    ? `Applied coupon worth $${coupon.amount.toFixed(2)}. `
                    : ''}{' '}
                  Please provide payment details to pay ${amount.toFixed(2)}.
                </div>
              </Col>
              <PaymentForm setSelectedCard={setSelectedCard} />
            </>
          ) : (
            <Col xl={12} lg={12} md={12} sm={12}>
              <div className={styles.inputLabel}>
                {coupon ? `Applied coupon worth $${coupon.amount}. ` : ''}
              </div>
            </Col>
          )}
        </>
      ) : null}
      {apiError && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.jobSubmitError}>
            <Alert variant={'danger'} className={styles.apiError}>
              <div className={styles.error}>{apiError}</div>
            </Alert>
          </div>
        </Col>
      )}
      <Col xl={12} lg={12} md={12} sm={12}>
        <Button
          onClick={handleSubmit}
          buttonType="button"
          type="large mt-5 py-3 w-100 outline-gray"
          label={postId && !relist ? 'Update Post' : 'Create Post'}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </Col>
      {apiSuccess && (
        <Col xl={12} lg={12} md={12} sm={12}>
          <div className={styles.jobSubmitSuccess}>
            <Redirect to={`${ROUTES.CAREER}/${jobId}`} />
          </div>
        </Col>
      )}

      <Col xl={12} lg={12} md={12} sm={12}>
        <div className={styles.subText}>
          Posts will last 30 days, you can refresh a post via your dashboard if
          you wish to continue the search.
        </div>
      </Col>
    </Row>
  );
};

export default React.memo(Post);
