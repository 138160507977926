import React, { Fragment } from 'react';
import moment from 'moment';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import { JOB_TYPE } from 'constants/Constants';

import DefaultImg from 'assets/imgs/defaultimg.png';

// SCSS
import styles from './Sidebar.module.scss';

interface Props {
  logo?: any;
  postedDate?: string;
  positionType?: string;
  location?: string;
  referenceLinks?: Array<string>;
  category: string;
  fee?: number;
  type: string;
  expertise?: string;
  experience?: string;
  credentialTitle?: string;
  education?: string;
  ideology?: string;
  partyAffiliation?: string;
}

const Sidebar: React.FC<Props> = ({
  logo,
  postedDate,
  location,
  referenceLinks,
  fee,
  type,
  expertise,
  experience,
  credentialTitle,
  education,
  ideology,
  partyAffiliation,
}: Props) => {
  const renderTooltipCom = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      Paid Opportunities are services where the applicant pays the posting party
      (outside of CC), while Free Opportunities do not require any payment.
    </Tooltip>
  );

  return (
    <Fragment>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarImg}>
          <img src={logo || DefaultImg} alt="" />
        </div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Posted</div>
        <div className={styles.sidebarResult}>
          {moment(postedDate).fromNow()}
        </div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>
          {type === JOB_TYPE.career ? 'Job Expertise' : 'Service Expertise'}
        </div>
        <div className={styles.sidebarResult}>{expertise || 'N/A'}</div>
      </div>
      {type === JOB_TYPE.career && (
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarLabel}>Credential Required</div>
          <div className={styles.sidebarResult}>{credentialTitle || 'N/A'}</div>
        </div>
      )}
      {type === JOB_TYPE.career && (
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarLabel}>Experience</div>
          <div className={styles.sidebarResult}>{experience || 'N/A'}</div>
        </div>
      )}
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Education</div>
        <div className={styles.sidebarResult}>{education || 'N/A'}</div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Location</div>
        <div className={styles.sidebarResult}>{location}</div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Company Partisan</div>
        <div className={styles.sidebarResult}>{partyAffiliation || 'N/A'}</div>
      </div>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarLabel}>Client Ideology</div>
        <div className={styles.sidebarResult}>{ideology || 'N/A'}</div>
      </div>
      {type === JOB_TYPE.opportunity && (
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarLabel}>
            Service Fee
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltipCom}
            >
              <div className={styles.infoHover}>i</div>
            </OverlayTrigger>
          </div>
          <div className={styles.sidebarResult}>{fee ? `$${fee}` : 'Free'}</div>
        </div>
      )}
      {referenceLinks && referenceLinks.length > 0 ? (
        <div className={styles.sidebarBlock}>
          <div className={styles.sidebarLabel}>Links</div>
          <div className={styles.sidebarResult}>
            {referenceLinks.map((link) => (
              <div key={link} className={styles.referenceLink}>
                <a
                  key={link}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {link}
                </a>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </Fragment>
  );
};

export default React.memo(Sidebar);
