import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';

// Components
import Button from 'components/Button/Button';
import Result from '../../../JobBoard/Result/Result';
import DefaultImg from 'assets/imgs/defaultimg.png';

// Constants
import { ROUTES, PARTY_IMAGES } from 'constants/Constants';

// Interfaces
import { Job } from 'interfaces/Job.types';

// SCSS
import styles from './Listing.module.scss';

interface Props {
  careers: Array<Job> | null;
  careersCount: number | null;
  opportunities: Array<Job> | null;
  opportunityCount: number | null;
}

const Listing: React.FC<Props> = ({
  careers,
  careersCount,
  opportunities,
  opportunityCount,
}: Props) => {
  const getLogo = (jobDetails: any) => {
    let logo = jobDetails.company_logo || '';
    if (!logo && jobDetails.default_icon) {
      logo = PARTY_IMAGES.find((item) => item.label === jobDetails.default_icon)
        .image;
    }
    return logo;
  };

  return (
    <Fragment>
      {/* Job Board Listing - Opportunity */}
      <div className={styles.listingTable}>
        <Row className={`${styles.listingHeader} align-items-center`}>
          <Col
            xl={4}
            lg={4}
            md={12}
            sm={12}
            className="text-xl-left text-lg-left text-md-center text-sm-center text-center"
          >
            <div className={styles.listingTitle}>Find a service.</div>
          </Col>
          <Col className="d-xl-block d-lg-block d-md-none d-sm-none d-none"></Col>
          <Col
            xl={4}
            lg={4}
            md={12}
            sm={12}
            className="text-xl-right text-lg-right text-md-center text-sm-center text-center"
          >
            <div className={styles.listingDesc}>
              Find help for consulting, advisors, service professionals, and
              more.
              <div className={styles.listingMore}>
                <Link to={ROUTES.OPPORTUNITIES}>Learn More</Link>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            className={`${styles.listingLabel}`}
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <span>Recent Services</span>
          </Col>
        </Row>
        <div className={styles.resultsOuter}>
          {opportunities &&
            opportunities
              .slice(0, 3)
              .map((career) => (
                <Result
                  key={career.id}
                  id={career.id}
                  image={getLogo(career) || DefaultImg}
                  title={career.title}
                  category={career.category}
                  location={career.location}
                  type={career.expected_need}
                  date={career.created_at}
                  city={career.city}
                  state={career.state}
                  jobType={career.type}
                />
              ))}
        </div>
        {!!opportunityCount && opportunityCount > 3 && (
          <Row>
            <Col
              className={`${styles.listingViewAll}`}
              xl={12}
              lg={12}
              md={12}
              sm={12}
            >
              <Link to={ROUTES.OPPORTUNITIES}>
                <Button type="large" label="View All" />
              </Link>
            </Col>
          </Row>
        )}
      </div>

      {/* Job Board Listing - Career */}
      <div className={`mb-0 mt-0 ${styles.listingTable}`}>
        <Row className={`${styles.listingHeader} align-items-center`}>
          <Col
            xl={4}
            lg={4}
            md={12}
            sm={12}
            className="text-xl-left text-lg-left text-md-center text-sm-center text-center"
          >
            <div className={styles.listingTitle}>Find a new job.</div>
          </Col>
          <Col className="d-xl-block d-lg-block d-md-none d-sm-none d-none"></Col>
          <Col
            xl={4}
            lg={4}
            md={12}
            sm={12}
            className="text-xl-right text-lg-right text-md-center text-sm-center text-center"
          >
            <div className={styles.listingDesc}>
              Everything from full-time to contract work, internships and more.
              <div className={styles.listingMore}>
                <Link to={ROUTES.CAREERS}>Learn More</Link>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col
            className={`${styles.listingLabel}`}
            xl={12}
            lg={12}
            md={12}
            sm={12}
          >
            <span>Recent Jobs</span>
          </Col>
        </Row>

        <div className={styles.resultsOuter}>
          {careers &&
            careers
              .slice(0, 3)
              .map((career) => (
                <Result
                  key={career.id}
                  id={career.id}
                  image={getLogo(career) || DefaultImg}
                  title={career.title}
                  category={career.category}
                  location={career.location}
                  type={career.expected_need}
                  date={career.created_at}
                  city={career.city}
                  state={career.state}
                  jobType={career.type}
                />
              ))}
        </div>

        {!!careersCount && careersCount > 3 && (
          <Row>
            <Col
              className={`${styles.listingViewAll}`}
              xl={12}
              lg={12}
              md={12}
              sm={12}
            >
              <Link to={ROUTES.CAREERS}>
                <Button type="large" label="View All" />
              </Link>
            </Col>
          </Row>
        )}
      </div>
    </Fragment>
  );
};

export default React.memo(Listing);
