import React from 'react';
import { Form } from 'react-bootstrap';

// SCSS
import styles from './TextArea.module.scss';

interface TextArea {
  placeholder?: string;
  name?: string;
  value?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
}

const TextArea = (props: TextArea) => {
  return (
    <Form.Control
      className={`${styles.mdrTextArea} ${props.className}`}
      placeholder={props.placeholder}
      as="textarea"
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      maxLength={props.maxLength || 10000}
    />
  );
};

export default React.memo(TextArea);
