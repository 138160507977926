import React, { Fragment, useState, useEffect, useContext } from 'react';

import { Link, useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import { Row, Col, Tabs, Tab, Alert } from 'react-bootstrap';

// Components
import Input from '../../../../../components/Form/Input/Input';
import Loader from 'components/Loader/Loader';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

// Context
import GlobalContext from 'contexts/Global.context';

// Interfaces
import { Conversation } from 'interfaces/Message.types';

// Services
import { getAllConversationsService } from 'services/MessageService';

// Styles
import styles from './Messages.module.scss';

const Messages = () => {
  const { url } = useRouteMatch();

  const {
    userDetails: { id: loggedInUserId },
  } = useContext(GlobalContext);

  // conversations related state
  const [loading, setLoading] = useState<boolean>(false);
  const [conversations, setConversations] = useState<Array<
    Conversation
  > | null>(null);
  const [error, setError] = useState<string>('');

  const [unreadCount, setUnreadCount] = useState<number>(0);

  const [currentTab, setCurrentTab] = useState<string>('unread');

  // search conversation state
  const [searchText, setSearchText] = useState<string>('');

  const fetchConversations = async (queryParams?: any) => {
    setLoading(true);
    setError('');
    setConversations(null);
    const { conversations, error } = await getAllConversationsService(
      queryParams
    );

    if (conversations) {
      setConversations(conversations);
      if (queryParams && queryParams.unread) {
        setUnreadCount(conversations.length);
      }
    } else if (error) {
      setError(error);
    }

    setLoading(false);
  };

  // search on enter/return key press
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13 || event.charCode === 13) {
      searchMessages();
    }
  };

  const searchMessages = async () => {
    fetchConversations({ body: searchText });
  };

  useEffect(() => {
    if (currentTab === 'unread') {
      fetchConversations({ unread: true });
    } else if (currentTab === 'all') {
      fetchConversations();
    }
  }, [currentTab]);

  const renderConversations = () => {
    return (
      <div className={styles.conversationsList}>
        {loading ? <Loader /> : null}
        {conversations &&
          conversations.length > 0 &&
          conversations.map((conversation) => (
            <div key={conversation.id} className={styles.messageList}>
              <div className={styles.list}>
                <div className={styles.img}>
                  <img
                    src={conversation.photo || 'https://placehold.co/70x70'}
                    alt={conversation.user_name}
                  />
                </div>
                <div className={styles.messageInfo}>
                  <div className={styles.MessageHeading}>
                    <Link
                      to={{
                        pathname: `${url}/${
                          loggedInUserId === conversation.sender
                            ? conversation.receiver
                            : conversation.sender
                        }`,
                        state: {
                          userName: conversation.user_name,
                          read: conversation.read,
                        },
                      }}
                      dangerouslySetInnerHTML={{ __html: conversation.body }}
                    />
                  </div>
                  <div className={styles.user}>{conversation.user_name}</div>
                  <div className={styles.time}>
                    {moment(conversation.created_at).fromNow()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        {error && !conversations ? (
          <Alert variant="danger">{error}</Alert>
        ) : null}
        {conversations && conversations.length === 0 && (
          <Alert variant="primary">No Messages Found</Alert>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>Messages</div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.messageListing}>
                  <div className={styles.searchBox}>
                    <Input
                      name="Search Messages"
                      placeholder="Search Messages"
                      type="text"
                      className={styles.mdrInputSmall}
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    <FontAwesomeIcon
                      icon={faSearch}
                      className={styles.searchIcon}
                      onClick={searchMessages}
                    />
                  </div>
                  <Tabs
                    activeKey={currentTab}
                    id="messagesTabs"
                    onSelect={(key: string) => setCurrentTab(key)}
                  >
                    <Tab
                      eventKey="unread"
                      title={`Unread (${unreadCount})`}
                      className={`${styles.tabsItems} ${
                        currentTab === 'unread' ? styles.active : ''
                      }`}
                    >
                      {renderConversations()}
                    </Tab>

                    <Tab
                      eventKey="all"
                      title="All"
                      className={`${styles.tabsItems} ${
                        currentTab === 'all' ? styles.active : ''
                      }`}
                    >
                      {renderConversations()}
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Messages;
