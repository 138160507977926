export const DEFAULT_CAREER_ERRORS = {
  title: '',
  category: '',
  rate: '',
  expectedNeed: '',
  location: '',
  companyName: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: '',
  externalUrl: '',
  deadline: '',
  isFeatured: '',
  ideology: '',
  partyAffiliation: '',
  minPay: '',
  maxPay: '',
  expertise: '',
  experience: '',
  education: '',
  credentialTitle: '',
  defaultIcon: '',
};

export const DEFAULT_CAREER_VALUES = {
  type: '',
  title: '',
  category: [],
  rate: '',
  expectedNeed: '',
  location: '',
  companyName: '',
  companyLogo: '',
  deadline: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: '',
  externalUrl: '',
  isFeatured: false,
  ideology: '',
  partyAffiliation: '',
  minPay: '',
  maxPay: '',
  expertise: '',
  experience: '',
  education: '',
  credentialTitle: '',
  defaultIcon: '',
};

export const DEFAULT_OPPORTUNITY_ERRORS = {
  title: '',
  category: '',
  rate: '',
  expectedNeed: '',
  location: '',
  companyName: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  applyThrough: '',
  externalUrl: '',
  pay: '',
  fee: '',
  referenceLinks: ['', '', ''],
  isFeatured: '',
  ideology: '',
  partyAffiliation: '',
  expertise: '',
  education: '',
  credential: '',
  defaultIcon: '',
};

export const DEFAULT_OPPORTUNITY_VALUES = {
  type: '',
  title: '',
  category: [],
  pay: '',
  rate: '',
  expectedNeed: '',
  location: '',
  companyName: '',
  companyLogo: '',
  zip: '',
  state: '',
  city: '',
  opportunityDescription: '',
  hiringDescription: '',
  fee: '',
  applyThrough: '',
  externalUrl: '',
  referenceLinks: ['', '', ''],
  isFeatured: false,
  ideology: '',
  partyAffiliation: '',
  expertise: '',
  education: '',
  credential: '',
  defaultIcon: '',
};
