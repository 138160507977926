import React, { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import SVG from 'react-inlinesvg';

import { Container, Row, Col } from 'react-bootstrap';

import Credentials from 'components/Credentials/Credentials';

// SVGs
import PDF from 'assets/svgs/pdf.svg';
// import Certificate from 'assets/svgs/mixing.svg';

// Context
import GlobalContext from 'contexts/Global.context';

// Interfaces
import { ApplicantProfile } from 'interfaces/User.types';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowDown,
  faArrowCircleUp,
} from '@fortawesome/free-solid-svg-icons';

// Constants
import { ROUTES } from 'constants/Constants';

// Styles
import styles from './Content.module.scss';

interface Props {
  profile: ApplicantProfile;
}

const Content: React.FC<Props> = ({ profile }: Props) => {
  const {
    userDetails: { id: loggedInUserId },
  } = useContext(GlobalContext);

  const renderSkills = (key: string) => {
    // eslint-disable-next-line react/prop-types
    return (
      <div className={styles.skills}>
        {profile[key]
          ? // eslint-disable-next-line react/prop-types
            profile[key].split(',').map((skill) => (
              <div key={skill} className={styles.skill}>
                {skill.trim()}
              </div>
            ))
          : null}
      </div>
    );
  };

  const getClickableLink = (link) => {
    return link.startsWith('http://') || link.startsWith('https://')
      ? link
      : `//${link}`;
  };

  const renderAdditionalLinks = () => {
    // eslint-disable-next-line react/prop-types
    const { additional_links: additionalLinks } = profile;
    if (additionalLinks && additionalLinks.length) {
      return (
        <div className={styles.box}>
          <div className={styles.label}>Additional Links</div>
          <div className={styles.boxContent}>
            <ul>
              {additionalLinks.map((additionalLink) => {
                if (additionalLink.title && additionalLink.link) {
                  return (
                    <li key={`${additionalLink.title}-${additionalLink.link}`}>
                      <a
                        href={getClickableLink(additionalLink.link)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {additionalLink.title}
                      </a>
                    </li>
                  );
                }
                return null;
              })}
            </ul>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Container fluid>
        <Container className={styles.profileContentContainer}>
          <Row className="justify-content-md-center">
            <Col className={styles.p0}>
              <div className={styles.profileContentWrapper}>
                <div className={styles.leftContent}>
                  <div className={styles.skillsBox}>
                    <div className={styles.label}>Seeking</div>
                    <div className={styles.boxContent}>
                      {renderSkills('career_categories')}
                    </div>
                  </div>
                  <div className={styles.skillsBox}>
                    <div className={styles.label}>Offering</div>
                    <div className={styles.boxContent}>
                      {renderSkills('opportunity_categories')}
                    </div>
                  </div>
                  <div className={styles.box}>
                    <div className={styles.label}>Joined</div>
                    <div className={styles.boxContent}>
                      <div className={styles.duration}>
                        {moment(profile.created_at).fromNow()}
                      </div>
                    </div>
                  </div>
                  {profile.id !== loggedInUserId && (
                    <div className={styles.box}>
                      <div className={styles.label}>Contact</div>
                      <div className={styles.boxContent}>
                        <Link
                          to={{
                            pathname: `${ROUTES.DASHBOARD}${ROUTES.MY_MESSAGES}/${profile.id}`,
                            state: { userName: profile.user_name },
                          }}
                        >
                          Message Me
                        </Link>
                      </div>
                    </div>
                  )}
                  <div className={styles.box}>
                    <div className={styles.label}>Profile Link</div>
                    <div className={styles.boxContent}>
                      <ul>
                        <li>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={window.location.href.split('?')[0]}
                          >
                            {profile.slug}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className={styles.box}>
                    <div className={styles.label}>Websites</div>
                    <div className={styles.boxContent}>
                      <ul>
                        {profile.twitter && (
                          <li>
                            <a
                              href={getClickableLink(profile.twitter)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faTwitter}
                                  className={styles.socialIcon}
                                />
                              </span>
                              Twitter
                            </a>
                          </li>
                        )}
                        {profile.facebook && (
                          <li>
                            <a
                              href={getClickableLink(profile.facebook)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faFacebook}
                                  className={styles.socialIcon}
                                />
                              </span>
                              Facebook
                            </a>
                          </li>
                        )}
                        {profile.instagram && (
                          <li>
                            <a
                              href={getClickableLink(profile.instagram)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faInstagram}
                                  className={styles.socialIcon}
                                />
                              </span>
                              Instagram
                            </a>
                          </li>
                        )}
                        {profile.linkedin && (
                          <li>
                            <a
                              href={getClickableLink(profile.linkedin)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faLinkedin}
                                  className={styles.socialIcon}
                                />
                              </span>
                              Linkedin
                            </a>
                          </li>
                        )}
                        {profile.website && (
                          <li>
                            <a
                              href={getClickableLink(profile.website)}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>
                                <FontAwesomeIcon
                                  icon={faArrowCircleUp}
                                  className={styles.socialIcon}
                                />
                              </span>
                              Website
                            </a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                  {renderAdditionalLinks()}
                </div>
                <div className={styles.rightContent}>
                  {/* <div className={styles.subHeading}>About</div>
                  <div className={styles.description}>{profile.about}</div> */}
                  <div className={styles.subHeading}>About</div>
                  <div className={styles.description}>
                    <pre>{profile.bio}</pre>
                  </div>
                  {loggedInUserId === profile.id && profile.resume ? (
                    <>
                      <div className={styles.subHeading}>My Resume</div>
                      <div className={styles.downloadResume}>
                        <a
                          href={profile.resume}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          <div className={styles.pdfIcon}>
                            <SVG baseURL="/" description="PDF" src={PDF} />
                          </div>
                          <div className={styles.fileType}>
                            <div className={styles.fileName}>My Resume</div>
                            <div className={styles.fileExt}>
                              {profile.resume.substring(
                                profile.resume.lastIndexOf('/') + 1
                              )}
                            </div>
                          </div>
                          <div className={styles.download}>
                            <FontAwesomeIcon icon={faArrowDown} />
                          </div>
                        </a>
                      </div>
                    </>
                  ) : null}
                  <div className={styles.studiesExp}>
                    <Row>
                      <Col md={6} xs={12}>
                        <div className={styles.subHeading}>Experience</div>
                        {profile?.campaigns?.map((campaign) => (
                          <div key={campaign.name} className={styles.exContent}>
                            <div className={styles.exName}>{campaign.name}</div>
                            <div className={styles.exType}>
                              {campaign.title}
                            </div>
                            <div className={styles.exDuration}>
                              {campaign.date_range}
                            </div>
                          </div>
                        ))}
                      </Col>
                      <Col md={6} xs={12}>
                        <div className={styles.subHeading}>Education</div>
                        {profile?.schools?.map((school) => (
                          <div key={school.level} className={styles.exContent}>
                            <div className={styles.exName}>{school.level}</div>
                            <div className={styles.exType}>{school.name}</div>
                            <div
                              className={`${styles.exDuration} ${styles.uniName}`}
                            >
                              {school.notes}
                            </div>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </div>
                  {!!profile.certificates?.length && (
                    <>
                      <div className={styles.subHeading}>
                        Verified Certificates
                      </div>
                      <div>
                        <Row>
                          <Col>
                            <Credentials credentials={profile.certificates} />
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
};

export default Content;
