import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext,
} from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import findIndex from 'lodash/findIndex';

// Page Components
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Content from './components/Content/Content';
import More from './components/More/More';

// Context
import GlobalContext from 'contexts/Global.context';

// Services
import { fetchJobDetailsService, fetchJobsService } from 'services/JobService';

// Interfaces
import { Job } from 'interfaces/Job.types';

// SCSS
import styles from './Page.module.scss';
import Loader from 'components/Loader/Loader';
import { JOB_TYPE, PARTY_IMAGES } from 'constants/Constants';

const Page: React.FC = () => {
  const { id } = useParams();

  const {
    userDetails: { email },
  } = useContext(GlobalContext);

  const [jobDetails, setJobDetails] = useState<Job | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [jobCategory, setJobCategory] = useState<string>('');
  const [relatedJobs, setRelatedJobs] = useState<Array<Job> | null>(null);
  const [relatedJobsLoading, setRelatedJobsLoading] = useState<boolean>(false);
  // const [relatedJobsError, setRelatedJobsError] = useState<string>('');

  const fetchRelatedJobs = useCallback(
    async (jobType?: string, category?: string) => {
      setRelatedJobsLoading(true);
      const { jobs } = await fetchJobsService({
        type: jobType,
        keyword: category,
      });

      if (jobs) {
        const sameJobIndex = findIndex(jobs, { id: id });
        if (sameJobIndex > -1) {
          jobs.splice(sameJobIndex, 1);
        }
        if (jobs.length) {
          setRelatedJobs(jobs);
        }
      }
      setRelatedJobsLoading(false);
    },
    [id]
  );

  const callAnalytics = useCallback(
    (jobDetails) => {
      window.analytics.track(
        JOB_TYPE.career === jobDetails.type
          ? 'Viewed a job listing'
          : 'Viewed an opportunity',
        {
          title: jobDetails.title,
          type: JOB_TYPE,
          category: jobDetails.category,
          location: jobDetails.location,
          pay: jobDetails.rate,
          jobDescription: jobDetails.opportunity_description,
          aboutCompany: jobDetails.hiring_description,
          hiringDescription: jobDetails.hiring_description,
          viewedBy: email,
        }
      );
    },
    [email]
  );

  const fetchJobDetails = useCallback(async () => {
    setLoading(true);
    const { jobDetails, error } = await fetchJobDetailsService(id);
    if (jobDetails) {
      setJobDetails(jobDetails);
      setJobCategory(jobDetails.category);
      fetchRelatedJobs(jobDetails.type, jobDetails.category);
      callAnalytics(jobDetails);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [id, fetchRelatedJobs, callAnalytics]);

  useEffect(() => {
    fetchJobDetails();
  }, [fetchJobDetails]);

  const getLogo = () => {
    let logo = jobDetails.company_logo || '';
    if (!logo && jobDetails.default_icon) {
      logo = PARTY_IMAGES.find((item) => item.label === jobDetails.default_icon)
        .image;
    }
    return logo;
  };

  return (
    <Container className={styles.resultPage} fluid>
      {jobDetails && (
        <Fragment>
          <Helmet>
            <title>
              {jobDetails.title} - {jobDetails.category} - Capitol Community{' '}
            </title>
            <meta
              name="description"
              content={`${jobDetails.city}, ${
                jobDetails.state
              } - ${jobDetails.opportunity_description.substr(0, 150)}`}
            />
          </Helmet>
          <Header
            jobId={jobDetails.id}
            title={jobDetails.title}
            location={`${jobDetails.city}, ${jobDetails.state}`}
            label={jobDetails.category}
            rate={jobDetails.rate}
            pay={
              jobDetails.pay !== null || jobDetails.type !== 'opportunity'
                ? jobDetails.pay
                : jobDetails.fee
            }
            minPay={jobDetails.min_pay}
            maxPay={jobDetails.max_pay}
            postedBy={jobDetails.user}
            applied={jobDetails.applied}
            externalURL={jobDetails.external_url}
            type={jobDetails.type}
            applyThrough={jobDetails.apply_through}
            company={jobDetails.company_name}
            isArchived={jobDetails.is_deleted}
          />
        </Fragment>
      )}

      <Container className={`p-0 ${styles.jobDetailsContainer}`}>
        {jobDetails && !loading && !error && (
          <Row className={styles.resultMain}>
            <Col xl lg md={12} sm={12} className={styles.resultSidebar}>
              <Sidebar
                logo={getLogo()}
                postedDate={jobDetails.created_at}
                positionType={jobDetails.expected_need}
                location={jobDetails.location}
                referenceLinks={jobDetails.reference_links}
                category={jobDetails.category}
                fee={jobDetails.fee}
                type={jobDetails.type}
                expertise={jobDetails.expertise}
                experience={jobDetails.experience}
                education={jobDetails.education}
                ideology={jobDetails.ideology}
                partyAffiliation={jobDetails.party_affiliation}
                credentialTitle={jobDetails.credential_title}
              />
            </Col>
            <Col xl lg md={12} sm={12} className={styles.resultContent}>
              <Content
                jobDescription={jobDetails.opportunity_description}
                aboutTheLabel={jobDetails.hiring_description}
                postedBy={jobDetails.user}
              />

              {!relatedJobsLoading && relatedJobs && (
                <Col
                  xl
                  lg
                  md={12}
                  sm={12}
                  className={`p-0 ${styles.resultMore}`}
                >
                  <More
                    jobType={jobDetails.type}
                    jobCategory={jobCategory}
                    jobs={relatedJobs}
                  />
                </Col>
              )}

              {relatedJobsLoading && <Loader />}
            </Col>
          </Row>
        )}

        {!jobDetails && loading && <Loader />}
      </Container>
    </Container>
  );
};

export default React.memo(Page);
