import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

// SCSS
import styles from './Hero.module.scss';

// Constants
import { ROUTES } from 'constants/Constants';

// IMGs
import HeroBanner from '../../../../assets/imgs/Hero/hero-bg.png';

// SVGs
import Gesture from '../../../../assets/svgs/Hero/gesture.svg';
import Blob from '../../../../assets/imgs/blob.png';
import Studio from '../../../../assets/svgs/Hero/studio.svg';
//import Blob2 from '../../../../assets/svgs/Hero/blob-2.svg';

const Hero: React.FC = () => {
  return (
    <Container className={`p-xl-0 p-0 ${styles.heroWrap}`} fluid>
      <Row className={`${styles.heroBanner}`}>
        <div className={styles.heroBannerImage}>
          <img
            src={HeroBanner}
            alt="Join one of the biggest music career job boards"
          />
        </div>
        <Col className={styles.textWrapper}>
          <div className={styles.heroBubble}>
            <div className={styles.heroBubbleData}>
              <div className={styles.heroTitle}>
                Welcome to your professional political community.
              </div>
              <div className={styles.heroDesc}>
                Find jobs, post services, network, and so much more.
              </div>
              <div className={styles.heroLink}>
                <a href={ROUTES.CAREERS} rel="noopener noreferrer">
                  View all Job Opportunities
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          className={`${styles.heroBlock} ${styles.heroBlockOpportunities}`}
          xl={6}
          lg={6}
          md={12}
          sm={12}
        >
          <Link to={ROUTES.OPPORTUNITIES}>
            <div className={styles.heroBlockWrap}>
              <Row>
                <Col xl={8} lg={8} md={12} sm={12}>
                  <div className={styles.heroBlockLabel}>SERVICES</div>
                  <div className={styles.heroBlockTitle}>Looking for help?</div>
                  <div className={styles.heroBlockDesc}>
                    Find professional service providers in the political
                    industry.
                  </div>
                </Col>
                <Col
                  className={styles.heroIconCol}
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                >
                  <div className={styles.heroBlockIconWrap}>
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.heroBlockIcon}
                      description="Opportunities"
                      loader={<span>Loading...</span>}
                      src={Gesture}
                    />
                    {/* <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.heroBlockIconBg}
                      description="Opportunities BG"
                      loader={<span>Loading...</span>}
                      src={Blob}
                    /> */}

                    <img
                      src={Blob}
                      className={styles.heroBlockIconBg}
                      alt="Blob"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Link>
        </Col>
        <Col
          className={`${styles.heroBlock} ${styles.heroBlockCareers}`}
          xl={6}
          lg={6}
          md={12}
          sm={12}
        >
          <Link to={ROUTES.CAREERS}>
            <div className={styles.heroBlockWrap}>
              <Row>
                <Col xl={8} lg={8} md={12} sm={12}>
                  <div className={styles.heroBlockLabel}>JOBS</div>
                  <div className={styles.heroBlockTitle}>Looking for work?</div>
                  <div className={styles.heroBlockDesc}>
                    Everything from full-time to contract work, internships, and
                    more.
                  </div>
                </Col>
                <Col
                  className={styles.heroIconCol}
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                >
                  <div className={styles.heroBlockIconWrap}>
                    <SVG
                      baseURL="/"
                      cacheRequests={true}
                      className={styles.heroBlockIcon}
                      description="Careers"
                      loader={<span>Loading...</span>}
                      src={Studio}
                    />
                    {/* <SVG
                    baseURL="/"
                    cacheRequests={true}
                    className={styles.heroBlockIconBg}
                    description="Careers BG"
                    loader={<span>Loading...</span>}
                    src={Blob}
                  /> */}
                    <img
                      src={Blob}
                      className={styles.heroBlockIconBg}
                      alt="Blob"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(Hero);
