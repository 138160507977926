import React, { useRef, useState, Fragment, useEffect } from 'react';

// Components
import { Container, Col, Row } from 'react-bootstrap';
import Button from 'components/Button/Button';
import Select from 'components/Form/Select/Select';

// Constants
import { FILE_TYPE } from 'constants/Constants';

// Styles
import styles from './FileUpload.module.scss';

interface FileInfo {
  name: string;
  type: string;
}

interface Props {
  fileType?: string;
  fileSpecs?: Array<string>;
  onFileChange: (file: File | null) => void;
  buttonStyles?: string;
  reset?: boolean;
  image?: string;
  alreadyUploadedFileURL?: string;
  currentUploadedFileName?: string;
  options?: Array<any>;
  selectedOption?: string;
  onOptionChange?: (evt: any) => void;
  name?: string;
  className?: string;
}

const FileUpload: React.FC<Props> = ({
  fileType,
  fileSpecs,
  onFileChange,
  buttonStyles,
  reset,
  image,
  alreadyUploadedFileURL,
  currentUploadedFileName,
  options,
  selectedOption,
  onOptionChange,
  name,
  className,
}: Props) => {
  const inputFileRef = useRef<any>(null);

  const [file, setFile] = useState<File | null>(null);

  const [preview, setPreview] = useState<string | null>(image || null);

  useEffect(() => {
    if (reset && file) {
      setFile(null);
      setPreview(null);
    }
  }, [reset, file]);

  const handleOnClick = () => {
    if (inputFileRef) {
      inputFileRef.current.click();
    }
  };

  const removeFile = () => {
    setFile(null);
    onFileChange(null);
  };

  const handleFileChange = (e: any) => {
    const { files } = e.target;

    if (files.length) {
      if (fileType === FILE_TYPE.IMAGE) {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onload = (evt: any) => {
          setPreview(evt.target.result);
        };
        reader.readAsDataURL(files[0]);
      }

      setFile(files[0]);
      onFileChange(files[0]);
    }
  };

  const imageURL = () => {
    if (preview) {
      return preview;
    }

    if (!preview && alreadyUploadedFileURL) {
      return alreadyUploadedFileURL;
    }
  };

  const handleDefaultChange = (event: any) => {
    onOptionChange(event);
    onFileChange(null);
    const selected = options.find(
      (option) => option.label === event.target.value
    );
    setPreview(selected.image);
  };

  return (
    <Container className={`mt-2 px-0 ${styles.fileUpload}`}>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Row>
            <Col xl={6} lg={6} md={12} sm={12}>
              <Button
                type={`${buttonStyles} button gray`}
                label="Upload"
                size="lg"
                onClick={handleOnClick}
              />
              <input
                id={styles.imageUpload}
                type="file"
                accept={
                  fileType === FILE_TYPE.IMAGE
                    ? 'image/*'
                    : '.pdf,.doc,.docx,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                }
                ref={inputFileRef}
                onChange={handleFileChange}
              />
              {fileSpecs && (
                <ul className={styles.fileSpecs}>
                  {fileSpecs.map((fs) => (
                    <li key={fs} className={styles.spec}>
                      {fs}
                    </li>
                  ))}
                </ul>
              )}
              {!!options && options.length && (
                <div className={styles.partyIconWrapper}>
                  <div className={styles.subLabel}>Use Default Party Icon</div>
                  <Select
                    id="option"
                    selectItems={options}
                    onChange={handleDefaultChange}
                    name={name}
                    value={selectedOption}
                  />
                </div>
              )}
            </Col>

            {fileType ? (
              <Col xl={6} lg={6} md={12} sm={12}>
                {fileType === FILE_TYPE.IMAGE ? (
                  <div className={`${styles.imageType} ${className}`}>
                    {imageURL() && <img src={imageURL()} alt={preview} />}
                    {/* {preview && <img src={preview} alt={preview} />} */}
                  </div>
                ) : (
                  <div></div>
                )}

                {fileType === FILE_TYPE.DOCUMENT && file && (
                  <Fragment>
                    <div className={styles.fileSelectedName}>{file.name}</div>
                    <div className={styles.removeFile} onClick={removeFile}>
                      Remove
                    </div>
                  </Fragment>
                )}

                {fileType === FILE_TYPE.DOCUMENT &&
                  currentUploadedFileName &&
                  !file && (
                    <Fragment>
                      <div className={styles.fileSelectedName}>
                        {currentUploadedFileName}
                      </div>
                      <div className={styles.removeFile} onClick={removeFile}>
                        Remove
                      </div>
                    </Fragment>
                  )}
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(FileUpload);
