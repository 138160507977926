import React, { Fragment, useContext, useState } from 'react';

import { Row, Col, Alert } from 'react-bootstrap';

// Components
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Form/Input/Input';

// Context
import GlobalContext from 'contexts/Global.context';

// Constants
import { APP_CONSTANTS } from 'constants/Constants';

// Services
import {
  updateUserAccountInfoService,
  closeUserAccountService,
} from 'services/UserService';

// Styles
import styles from './EditAccountInfo.module.scss';

interface Passwords {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface Errors {
  userName: string;
  newEmail: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  newSlug: string;
}

const {
  regExValidators: { EMAIL_VALIDATOR_REGEX, USER_NAME_VALIDATOR },
  errorConstants: {
    PASSWORD_CHAR_LENGTH_ERROR,
    PASSWORDS_MISMATCH_ERROR,
    USER_NAME_INVALID,
    SLUG_INVALID,
  },
} = APP_CONSTANTS;

const EditAccountInfo = () => {
  const {
    userDetails: { id, email, user_name, slug },
    setAuth,
    logout,
  } = useContext(GlobalContext);

  // general info state
  const [userName, setUserName] = useState<string>(user_name);
  const [newEmail, setNewEmail] = useState<string>(email);
  const [newSlug, setNewSlug] = useState<string>(slug);

  const [errors, setErrors] = useState<Errors>({
    userName: '',
    newEmail: '',
    newSlug: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // change password related state
  const [passwords, setPasswords] = useState<Passwords>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // api related state
  const [loading, setLoading] = useState<boolean>(false);
  const [newToken, setNewToken] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === 'userName') {
      if (value) {
        if (value !== user_name) {
          if (!USER_NAME_VALIDATOR.test(value)) {
            setErrors({
              ...errors,
              [name]: USER_NAME_INVALID,
            });
          } else {
            setErrors({
              ...errors,
              [name]: '',
            });
          }
        } else {
          setErrors({
            ...errors,
            [name]: 'Please enter a different user name',
          });
        }
      } else {
        setErrors({
          ...errors,
          [name]: 'Please enter a user name',
        });
      }

      setUserName(value);
    }

    if (name === 'newSlug') {
      if (value) {
        if (value !== slug) {
          if (!USER_NAME_VALIDATOR.test(value)) {
            setErrors({
              ...errors,
              [name]: SLUG_INVALID,
            });
          } else {
            setErrors({
              ...errors,
              [name]: '',
            });
          }
        } else {
          setErrors({
            ...errors,
            [name]: 'Please enter a different profile URL',
          });
        }
      } else {
        setErrors({
          ...errors,
          [name]: 'Please enter a profile URL',
        });
      }

      setNewSlug(value);
    }

    if (name === 'newEmail') {
      setNewEmail(value);
      if (value) {
        if (EMAIL_VALIDATOR_REGEX.test(value)) {
          if (value !== email) {
            setErrors({
              ...errors,
              [name]: '',
            });
          } else {
            setErrors({
              ...errors,
              [name]: 'Please enter a new email address',
            });
          }
        } else {
          setErrors({
            ...errors,
            [name]: 'Please enter a valid email address',
          });
        }
      } else {
        setErrors({
          ...errors,
          [name]: 'Please enter an email address',
        });
      }
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'newPassword') {
      if (value) {
        if (value.length >= 8) {
          setErrors({
            ...errors,
            [name]: '',
          });
        } else {
          setErrors({
            ...errors,
            [name]: PASSWORD_CHAR_LENGTH_ERROR,
          });
        }
      }
    }

    if (name === 'confirmPassword') {
      if (value) {
        if (passwords.newPassword && passwords.newPassword !== value) {
          setErrors({
            ...errors,
            [name]: PASSWORDS_MISMATCH_ERROR,
          });
        } else {
          setErrors({
            ...errors,
            [name]: '',
          });
        }
      }
    }

    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  const isSubmitDisabled = () => {
    const { currentPassword, newPassword, confirmPassword } = passwords;
    if (
      (userName &&
        userName !== user_name &&
        USER_NAME_VALIDATOR.test(userName)) ||
      (newSlug && newSlug !== slug && USER_NAME_VALIDATOR.test(newSlug)) ||
      (newEmail &&
        EMAIL_VALIDATOR_REGEX.test(newEmail) &&
        newEmail !== email) ||
      (currentPassword &&
        newPassword &&
        newPassword.length >= 8 &&
        confirmPassword &&
        confirmPassword === newPassword)
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const body = {
      user_name: userName !== user_name ? userName : '',
      email: newEmail !== email ? newEmail : '',
      slug: newSlug !== slug ? newSlug : '',
      old_password: passwords.currentPassword,
      password: passwords.newPassword,
    };

    setLoading(true);
    setError('');
    setNewToken('');
    const { token, error } = await updateUserAccountInfoService(id, body);

    if (token) {
      setNewToken(token);
      setAuth(token);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  };

  const closeAccount = async () => {
    const { isClosed } = await closeUserAccountService(id);

    if (isClosed) {
      logout();
    }
  };

  return (
    <Fragment>
      <div className={styles.dashboardSection}>
        <Row>
          <Col xl={9} lg={9} md={12} sm={12}>
            <Row>
              <Col>
                <div className={styles.sectionHeading}>Edit Account Info</div>
                <div className={styles.profileInfo}>
                  General Information
                  <span>Tell us about yourself..</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Username</div>
                  <Input
                    name="userName"
                    type="text"
                    value={userName}
                    onChange={handleInputChange}
                  />
                  {errors.userName ? (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>{errors.userName}</div>
                    </Alert>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Email</div>
                  <Input
                    name="newEmail"
                    type="email"
                    value={newEmail}
                    onChange={handleInputChange}
                  />
                  {errors.newEmail ? (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>{errors.newEmail}</div>
                    </Alert>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Profile URL</div>
                  <Input
                    name="newSlug"
                    type="text"
                    value={newSlug}
                    onChange={handleInputChange}
                  />
                  {errors.newSlug ? (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>{errors.newSlug}</div>
                    </Alert>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.profileInfo}>
                  Change Password
                  <span>Keep it complex and secure</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Current Password</div>
                  <Input
                    name="currentPassword"
                    type="password"
                    onChange={handlePasswordChange}
                  />
                  {errors.currentPassword ? (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {errors.currentPassword}
                      </div>
                    </Alert>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>New Password</div>
                  <Input
                    name="newPassword"
                    type="password"
                    onChange={handlePasswordChange}
                  />
                  {errors.newPassword ? (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>{errors.newPassword}</div>
                    </Alert>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Confirm Password</div>
                  <Input
                    name="confirmPassword"
                    type="password"
                    onChange={handlePasswordChange}
                  />
                  {errors.confirmPassword ? (
                    <Alert variant="danger" className={styles.apiError}>
                      <div className={styles.error}>
                        {errors.confirmPassword}
                      </div>
                    </Alert>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.profileInfo}>
                  Account Change
                  <span>Close your account</span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <div className={styles.authInput}>
                  <div className={styles.authLabel}>Close Account</div>
                  <div className={styles.authText}>
                    Delete your account and account data.
                  </div>
                </div>
              </Col>
              <Col xs={6}>
                <div className={styles.textRight}>
                  <Button
                    label="Close Account"
                    type="light-pink"
                    onClick={closeAccount}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className={styles.authButton}>
                  <Button
                    label={loading ? 'loading...' : 'Save All Information'}
                    type="large w-100"
                    disabled={isSubmitDisabled()}
                    onClick={handleSubmit}
                  />
                  {newToken ? (
                    <Alert variant="success">
                      Information updated successfully!!
                    </Alert>
                  ) : null}

                  {error ? (
                    <Alert variant="danger">
                      <div className={styles.error}>{error}</div>
                    </Alert>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default EditAccountInfo;
