import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import { Container, Row, Col, Nav } from 'react-bootstrap';
//import SVG from 'react-inlinesvg';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

// Constants
import { ROUTES } from 'constants/Constants';

// SCSS
import './Footer.scss';

// SVGs
import Logo from '../../assets/imgs/footerlogo.png';

const SOCIAL_LINKS = [
  {
    name: 'twitter',
    link: 'https://twitter.com/capitol_com',
    icon: faTwitter,
  },
  {
    name: 'facebook',
    link: 'https://www.facebook.com/CapitolCommunity',
    icon: faFacebookF,
  },
  {
    name: 'instagram',
    link: 'https://www.instagram.com/capitol_com/',
    icon: faInstagram,
  },
  {
    name: 'linkedin',
    link: 'http://linkedin.com/company/capitol-community',
    icon: faLinkedinIn,
  },
];

const Footer: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <Container className="footerWrap" fluid>
      <Container className="footer p-0">
        {pathname.includes(ROUTES.CASE_STUDY) && (
          <Row className="align-items-center disclaimer">
            <Col lg="auto" md={12} sm={12}>
              DISCLAIMER: No consideration or compensation was given to Beto For
              Texas or any campaign staff. Capitol Community is a free platform,
              with all features available for any candidate or campaign,
              regardless of location, party affiliation, or ideology. All images
              provided by Beto For Texas.
            </Col>
          </Row>
        )}
        <Row className="align-items-center">
          <Col lg="auto" md={12} sm={12}>
            <img alt="Capitol community" src={Logo} className="footerLogoImg" />
          </Col>
          <Col lg="auto" md={12} sm={12}>
            <Nav
              className="flex-xl-row flex-lg-row flex-md-column flex-sm-column flex-column"
              activeKey="/home"
            >
              <Nav.Item>
                <Nav.Link to={ROUTES.CAREERS} as={Link} eventKey="careers">
                  Job
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  to={ROUTES.OPPORTUNITIES}
                  as={Link}
                  eventKey="opportunities"
                >
                  Services
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  to={ROUTES.FIND_SOMEONE}
                  as={Link}
                  eventKey="post-a-job"
                >
                  Post
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link to={ROUTES.ABOUT} as={Link} eventKey="about">
                  About
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link to={ROUTES.SUPPORT} as={Link} eventKey="contact">
                  Contact
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link to={ROUTES.PRIVACY} as={Link} eventKey="privacy">
                  Privacy
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  to={ROUTES.TERMS}
                  as={Link}
                  eventKey="terms-of-service"
                >
                  Terms
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  to={ROUTES.CASE_STUDY}
                  as={Link}
                  eventKey="case-study"
                >
                  Case Study
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg="auto" md={12} sm={12}>
            <Nav className="p-0 social-menu flex-row justify-content-lg-start justify-content-md-center justify-content-sm-center justify-content-center">
              {SOCIAL_LINKS.map((socialLink) => (
                <a
                  key={socialLink.name}
                  href={socialLink.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={socialLink.icon} />
                </a>
              ))}
            </Nav>
          </Col>
          <Col
            className="text-xl-right text-lg-right text-md-center text-sm-center text-center copyrightOuter"
            lg="auto"
            md={12}
            sm={12}
          >
            <div className="copyright">
              &copy; 2022 Capitol Community, Inc. 2022
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default React.memo(Footer);
