import { API_ENDPOINTS, DEFAULT_PAGINATION_PARAMS } from 'constants/Constants';
import { API_METHODS, DEFAULT_API_HEADER } from 'config/ApiConfig';

import { stringifyMe, addParamsToURL } from 'utils/UtilityFunctions';

import { Pagination } from 'interfaces/Job.types';

/**
 *
 * @param job All the job details entered by user to post a career/opportunity
 * This service is used for creating a job
 */
export const createJobService = (job: any) => {
  return fetch(API_ENDPOINTS.JOBS, {
    method: API_METHODS.POST,
    headers: {
      ...DEFAULT_API_HEADER,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(job),
  });
};

// Query object = { type: 'career', expected_need: 'full time', pay: '', location: 'remote' }
// filters: { query: {}, limit: 10, skip: 0}
export const fetchJobsService = async (
  query?: any,
  paginationObj: Pagination = DEFAULT_PAGINATION_PARAMS
) => {
  try {
    let newQueryObj: any = {};
    // pick only the truthy values from the query object
    if (query && Object.keys(query).length) {
      Object.keys(query).forEach((q) => {
        if (query[q] && (query[q].length || typeof query[q] === 'boolean')) {
          newQueryObj[q] = query[q];
        }
      });
    }

    const response = await fetch(API_ENDPOINTS.OPPORTUNITIES, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: stringifyMe({
        query: newQueryObj,
        ...paginationObj,
      }),
    });

    if (response.ok) {
      const { jobs, count } = await response.json();
      return { jobs, jobsCount: count };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

// Fetch jobs details service
export const fetchJobDetailsService = async (jobId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.JOBS}/${jobId}`, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const jobDetails = await response.json();
      return { jobDetails };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (e) {
    return { error: e.message };
  }
};

// Fetch jobs link service
export const fetchJobLinkService = async (jobId: string) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.JOBS}/external-url/${jobId}`,
      {
        method: API_METHODS.GET,
        headers: {
          ...DEFAULT_API_HEADER,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.ok) {
      const { link } = await response.json();
      return { link };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (e) {
    return { error: e.message };
  }
};

export const applyForJobService = async (apiBody: any) => {
  try {
    const response = await fetch(API_ENDPOINTS.APPLY_FOR_JOB, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: stringifyMe({ ...apiBody }),
    });

    if (response.ok) {
      return { applied: true };
    } else {
      return { error: await response.clone().text() };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const updateJobService = async (id: string, apiBody: any) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.JOBS}/${id}`, {
      method: API_METHODS.PUT,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: stringifyMe({ ...apiBody }),
    });

    if (response.ok) {
      return { id };
    } else {
      return { error: await response.clone().text() };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchAppliedJobsService = async (
  userId: string,
  queryParams?: any
) => {
  let fetchURL = `${API_ENDPOINTS.APPLIED_JOBS}/${userId}`;
  try {
    if (queryParams) {
      fetchURL = `${fetchURL}?${addParamsToURL(queryParams)}`;
    }
    const response = await fetch(fetchURL, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const { jobs } = await response.json();
      return { jobs };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchApplicantsService = async (queryParams?: any) => {
  let fetchURL = API_ENDPOINTS.PENDING_APPLICATIONS;
  try {
    if (queryParams) {
      fetchURL = `${fetchURL}?${addParamsToURL(queryParams)}`;
    }
    const response = await fetch(fetchURL, {
      method: API_METHODS.GET,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const applicantions = await response.json();
      return { applicants: applicantions };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const updateApplicantsStatusService = async (
  applicationId: string,
  status?: any
) => {
  try {
    const response = await fetch(
      `${API_ENDPOINTS.APPLY_FOR_JOB}/${applicationId}`,
      {
        method: API_METHODS.PUT,
        headers: {
          ...DEFAULT_API_HEADER,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: stringifyMe({ ...status }),
      }
    );

    if (response.ok) {
      const isUpdated = await response.json();
      return { isUpdated };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const fetchArchivedJobsService = async (
  query?: any,
  paginationObj: Pagination = DEFAULT_PAGINATION_PARAMS
) => {
  try {
    let newQueryObj: any = {};
    // pick only the truthy values from the query object
    if (query && Object.keys(query).length) {
      Object.keys(query).forEach((q) => {
        if (query[q] && (query[q].length || query[q])) {
          newQueryObj[q] = query[q];
        }
      });
    }

    const response = await fetch(API_ENDPOINTS.OPPORTUNITIES, {
      method: API_METHODS.POST,
      headers: {
        ...DEFAULT_API_HEADER,
      },
      body: stringifyMe({
        query: newQueryObj,
        ...paginationObj,
      }),
    });

    if (response.ok) {
      const { jobs, count } = await response.json();
      return { jobs, jobsCount: count };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const deleteJobService = async (jobId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.JOBS}/${jobId}`, {
      method: API_METHODS.DELETE,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const { success } = await response.json();
      return { isDeleted: success };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};

export const relistAJobService = async (jobId: string) => {
  try {
    const response = await fetch(`${API_ENDPOINTS.JOBS}/${jobId}/repost`, {
      method: API_METHODS.PUT,
      headers: {
        ...DEFAULT_API_HEADER,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (response.ok) {
      const { success } = await response.json();
      return { isRelisted: success };
    } else {
      const error = await response.clone().text();
      return { error };
    }
  } catch (err) {
    return { error: err.message };
  }
};
