import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

// Components
import Header from './Components/Header/Header';
import Content from './Components/Content/Content';
import Loader from 'components/Loader/Loader';

// Interfaces
import { ApplicantProfile } from 'interfaces/User.types';

// Services
import { fetchApplicantProfileService } from 'services/UserService';

// Styles
import styles from './Profile.module.scss';

const Profile = () => {
  const { slug } = useParams();
  const { search: paramValue } = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<ApplicantProfile | null>(null);
  const [error, setError] = useState<string>('');

  const fetchUserProfile = useCallback(async () => {
    setLoading(true);
    const { profile, error } = await fetchApplicantProfileService(slug);

    if (profile) {
      setProfile(profile);
    } else if (error) {
      setError(error);
    }
    setLoading(false);
  }, [slug]);

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  useEffect(() => {
    document.body.className = 'profileBody';
    return () => {
      document.body.className = '';
    };
  });
  return (
    // @dev please add class on main header when profile page loads
    <Fragment>
      <div className={styles.profileWrapper}>
        {loading && <Loader />}
        {profile ? (
          <div>
            <Helmet>
              <title>
                {profile.first_name} {profile.last_name} - Capitol Community
                Careers{' '}
              </title>
            </Helmet>
            {paramValue === '?confirm=1' && (
              <Alert variant="warning" className="mx-5">
                Check your email for an account confirmation link.
              </Alert>
            )}
            <div className={styles.headerWrapper}>
              <Header profile={profile} />
            </div>
            <div className={styles.contentWrapper}>
              <Content profile={profile} />
            </div>
          </div>
        ) : null}
        {error && <Alert variant="danger">{error}</Alert>}
      </div>
    </Fragment>
  );
};

export default Profile;
