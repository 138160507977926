import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

//Components
import Welcome from './components/Welcome/Welcome';

// SCSS
import styles from './About.module.scss';

const About = () => {
  return (
    <div className={styles.aboutPage}>
      <Container className="p-0">
        <Row>
          <Col md={12}>
            <Welcome />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
