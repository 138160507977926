import React, { useContext } from 'react';

// Context
import GlobalContext from 'contexts/Global.context';

// Components
import Checkbox from 'components/Form/Checkbox/Checkbox';

import {
  AFFILIATIONS,
  EDUCATION_OPTIONS,
  EXPERIENCE_OPTIONS,
} from 'constants/Constants';

// SCSS
import styles from './Sidebar.module.scss';

interface Query {
  type: string;
  expectedNeed?: string;
  payment?: Array<Array<number>>;
  location?: string;
  fee?: string;
  forMeOnly?: boolean;
  partyAffiliation?: string;
  ideology?: string;
  education?: string;
  experience?: string;
}

interface FilterProps {
  title: string;
  type: string;
  checkboxValues: Query | any;
  onCategoryFilterChange: () => void;
  onPaymentFilterChange: (value: Array<Array<number>>) => void;
  onLocationFilterChange: (location: Array<string>) => void;
  onExpectedNeedChange: (need: Array<string>) => void;
  onRateChange: (need: Array<string>) => void;
  onCostFilterChange: (fee: Array<string>) => void;
  onAffiliationChange: (need: Array<string>) => void;
  onIdeologyChange: (need: Array<string>) => void;
  onEducationChange: (need: Array<string>) => void;
  onExperienceChange: (need: Array<string>) => void;
}

const EXPECTED_NEED = [
  { id: 'fullTime', label: 'Full Time', name: 'full time' },
  { id: 'partTime', label: 'Part Time', name: 'part time' },
  { id: 'onetime', label: 'One Time', name: 'one time' },
  { id: 'freelance', label: 'Freelance', name: 'freelance' },
  { id: 'internship', label: 'Internship', name: 'internship' },
];

const EXPECTED_NEED_OPPORTUNITY = [
  { id: 'fullTime', label: 'Full Time', name: 'full time' },
  { id: 'partTime', label: 'Part Time', name: 'part time' },
  { id: 'onetime', label: 'One Time', name: 'one time' },
  { id: 'freelance', label: 'Freelance', name: 'freelance' },
];

const RATE = [
  { id: 'free', label: 'Non-Paying', name: 'free' },
  { id: 'hour', label: 'Per Hour', name: 'hour' },
  { id: 'month', label: 'Monthly', name: 'month' },
  { id: 'flat', label: 'Flat Fee', name: 'flat' },
  { id: 'salary1', label: 'Salary', name: 'salary' },
  { id: 'TBD', label: 'TBD', name: 'TBD' },
];

const PAY_OPTIONS = [
  { id: 'tbd', label: 'TBD', value: 'TBD' },
  { id: 'less1k', label: '<1k', value: [0, 1000] },
  { id: '1kto10k', label: '1k - 10k', value: [1000, 10000] },
  { id: '10kto100k', label: '10k - 100k', value: [10000, 100000] },
  { id: '100kplus', label: '100k+', value: [100000, 999999999] },
];

const LOCATION_OPTIONS = [
  { id: 'remote', label: 'Remote', name: 'remote' },
  { id: 'onSite', label: 'Onsite (Office Location)', name: 'on site' },
  {
    id: 'onMultiSite',
    label: 'OnSite (Multiple Locations)',
    name: 'on multiple sites',
  },
];

const IDEOLOGY_OPTIONS = [
  { id: 'conservative', label: 'Conservative', name: 'Conservative' },
  { id: 'moderate', label: 'Moderate', name: 'Moderate' },
  { id: 'progressive', label: 'Progressive', name: 'Progressive' },
  {
    id: 'preferNot',
    label: 'Prefer not to answer',
    name: 'Prefer not to answer',
  },
];

const COST_OPTIONS = [
  { id: 'free_fee', label: 'Free', name: 'free' },
  { id: 'paid_fee', label: 'Paid', name: 'paid' },
];

let HASH: any = {};

PAY_OPTIONS.forEach((pay, index) => {
  HASH[index] = index;
});

const Filters = (props: FilterProps) => {
  const { isLoggedIn } = useContext(GlobalContext);

  const isCheckboxChecked = (parentType: string, name?: any) => {
    if (name && typeof name === 'object') {
      return JSON.stringify(props.checkboxValues[parentType]).includes(
        JSON.stringify(name)
      );
    }
    return props.checkboxValues[parentType]?.includes(name);
  };

  const handleExpectedNeedChange = (event: any) => {
    const { name } = event.target;
    const { expectedNeed } = props.checkboxValues;

    let arr = expectedNeed && expectedNeed.length ? expectedNeed : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onExpectedNeedChange(arr);
  };

  const handleRateChange = (event: any) => {
    const { name } = event.target;
    const { rate } = props.checkboxValues;

    let arr = rate && rate.length ? rate : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onRateChange(arr);
  };

  const handleLocationChange = (event: any) => {
    const { name } = event.target;
    const { location } = props.checkboxValues;

    let arr = location && location.length ? location : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onLocationFilterChange(arr);
  };

  const handleCostChange = (event: any) => {
    const { name } = event.target;
    const { fee } = props.checkboxValues;

    let arr = fee && fee.length ? fee : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onCostFilterChange(arr);
  };

  const handlePayChange = (event: any) => {
    const { id } = event.target;
    const { pay } = props.checkboxValues;
    const newArr = pay.map((p: any) => p) || [];
    const filterValue = PAY_OPTIONS.filter((pay) => pay.id === id)[0].value;
    const hashObj: any = {};
    for (let i = 0; i < pay.length; i++) {
      hashObj[pay[i]] = i;
    }

    // check if the value is already present in checkbox values
    const isValuePresent = Object.keys(hashObj).length
      ? // eslint-disable-next-line no-prototype-builtins
        hashObj.hasOwnProperty(filterValue)
      : false;
    if (isValuePresent) {
      // remove if filter is already selected
      const index = hashObj[filterValue.toString()];
      newArr.splice(index, 1);
      props.onPaymentFilterChange(newArr);
    } else {
      newArr.push(filterValue);
      props.onPaymentFilterChange(newArr);
    }
  };

  const handleAffiliationChange = (event: any) => {
    const { name } = event.target;
    const { partyAffiliation } = props.checkboxValues;

    let arr =
      partyAffiliation && partyAffiliation.length ? partyAffiliation : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onAffiliationChange(arr);
  };

  const handleIdeologyChange = (event: any) => {
    const { name } = event.target;
    const { ideology } = props.checkboxValues;

    let arr = ideology && ideology.length ? ideology : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onIdeologyChange(arr);
  };

  const handleEducationChange = (event: any) => {
    const { name } = event.target;
    const { education } = props.checkboxValues;

    let arr = education && education.length ? education : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onEducationChange(arr);
  };

  const handleExperienceChange = (event: any) => {
    const { name } = event.target;
    const { experience } = props.checkboxValues;

    let arr = experience && experience.length ? experience : [];
    if (arr.includes(name)) {
      const index = arr.indexOf(name);
      arr.splice(index, 1);
    } else {
      arr.push(name);
    }
    props.onExperienceChange(arr);
  };

  const renderExpectedNeedCheckboxes = (type: string) => {
    return (
      <div>
        {(type === 'career' ? EXPECTED_NEED : EXPECTED_NEED_OPPORTUNITY).map(
          (need) => (
            <Checkbox
              key={need.id}
              id={need.id}
              label={need.label}
              name={need.name}
              isChecked={isCheckboxChecked('expectedNeed', need.name)}
              onChange={handleExpectedNeedChange}
            />
          )
        )}
      </div>
    );
  };

  const renderRateCheckboxes = () => {
    return (
      <div>
        {RATE.map((need) => (
          <Checkbox
            key={need.id}
            id={need.id}
            label={need.label}
            name={need.name}
            isChecked={isCheckboxChecked('rate', need.name)}
            onChange={handleRateChange}
          />
        ))}
      </div>
    );
  };

  const renderCostCheckboxes = () => {
    return (
      <div>
        {COST_OPTIONS.map((need) => (
          <Checkbox
            key={need.id}
            id={need.id}
            label={need.label}
            name={need.name}
            isChecked={isCheckboxChecked('fee', need.name)}
            onChange={handleCostChange}
          />
        ))}
      </div>
    );
  };

  const renderPaymentCheckboxes = () => {
    return (
      <div>
        {PAY_OPTIONS.map((pay) => (
          <Checkbox
            key={pay.id}
            id={pay.id}
            label={pay.label}
            isChecked={isCheckboxChecked('pay', pay.value)}
            onChange={handlePayChange}
          />
        ))}
      </div>
    );
  };

  const renderLocationCheckboxes = () => {
    return (
      <div>
        {LOCATION_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.id}
            id={loc.id}
            label={loc.label}
            name={loc.name}
            isChecked={isCheckboxChecked('location', loc.name)}
            onChange={handleLocationChange}
          />
        ))}
      </div>
    );
  };

  const renderEducationCheckboxes = () => {
    return (
      <div>
        {EDUCATION_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.label}
            id={loc.label}
            label={loc.label}
            name={loc.label}
            isChecked={isCheckboxChecked('education', loc.label)}
            onChange={handleEducationChange}
          />
        ))}
      </div>
    );
  };

  const renderAffiliationCheckboxes = () => {
    return (
      <div>
        {AFFILIATIONS.map((loc) => (
          <Checkbox
            key={loc.label}
            id={loc.label}
            label={loc.label}
            name={loc.label}
            isChecked={isCheckboxChecked('partyAffiliation', loc.label)}
            onChange={handleAffiliationChange}
          />
        ))}
      </div>
    );
  };

  const renderExperienceCheckboxes = () => {
    return (
      <div>
        {EXPERIENCE_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.label}
            id={loc.label}
            label={loc.label}
            name={loc.label}
            isChecked={isCheckboxChecked('experience', loc.label)}
            onChange={handleExperienceChange}
          />
        ))}
      </div>
    );
  };

  const renderIdeologyCheckboxes = () => {
    return (
      <div>
        {IDEOLOGY_OPTIONS.map((loc) => (
          <Checkbox
            key={loc.label}
            id={loc.label}
            label={loc.label}
            name={loc.label}
            isChecked={isCheckboxChecked('ideology', loc.label)}
            onChange={handleIdeologyChange}
          />
        ))}
      </div>
    );
  };

  const renderCategoryCheckbox = () => {
    return (
      <div>
        <Checkbox
          id="forMeOnly"
          label="Listings For Me"
          name="forMeOnly"
          onChange={props.onCategoryFilterChange}
          isChecked={!!props.checkboxValues['forMeOnly']}
        />
      </div>
    );
  };

  const Accordion = ({ title, children }) => {
    const [openFilter, setOpenFilter] = React.useState(true);
    return (
      <div className="accordion-wrapper">
        <div
          className={`accordion-title ${openFilter ? '' : 'open'}`}
          onClick={() => setOpenFilter(!openFilter)}
        >
          {title}
        </div>
        <div className={`accordion-item ${!openFilter ? '' : 'collapsed'}`}>
          <div className="accordion-content">{children}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.sidebarData}>
      <div className={styles.sidebarBlock}>
        <div className={styles.sidebarDescription}>{props.title}</div>
      </div>

      {isLoggedIn && (
        <div className={`${styles.sidebarBlock}`}>
          <Accordion title="Recommended For Me">
            {renderCategoryCheckbox()}
          </Accordion>
        </div>
      )}

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title={props.type === 'career' ? 'Rate' : 'Fee Structure'}>
          {renderRateCheckboxes()}
        </Accordion>
      </div>

      {props.type === 'career' && (
        <div className={`${styles.sidebarBlock}`}>
          <Accordion title="Compensation">
            {renderPaymentCheckboxes()}
          </Accordion>
        </div>
      )}

      {props.type !== 'career' && (
        <div className={`${styles.sidebarBlock}`}>
          <Accordion title="Fee">{renderCostCheckboxes()}</Accordion>
        </div>
      )}

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Position">
          {renderExpectedNeedCheckboxes(props.type)}
        </Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Location">{renderLocationCheckboxes()}</Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Education">{renderEducationCheckboxes()}</Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Experience">{renderExperienceCheckboxes()}</Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion
          title={
            props.type === 'career' ? 'Job Affiliation' : 'Service Affiliation'
          }
        >
          {renderAffiliationCheckboxes()}
        </Accordion>
      </div>

      <div className={`${styles.sidebarBlock}`}>
        <Accordion title="Client Ideology">
          {renderIdeologyCheckboxes()}
        </Accordion>
      </div>
    </div>
  );
};

export default React.memo(Filters);
